import { Action, AnyAction, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import { useDispatch } from "react-redux";

import breadcrumbSlice from "store/breadcrumb/breadcrumb.slice";
import featureFlagsSlice from "store/feature-flags/feature-flags-slice";

import { timelineApi } from "@/api/query/verifier-timeline";

import errorReducer from "./errors/reducer";
import organisationReducer from "./organisation/reducer";
import snackbarReducer from "./snackbar/reducer";
import transactionsSlice from "./transactions/transactions-slice";
import userReducer from "./user/reducer";
import verificationReducer from "./verification/reducer";
import signupVerificationReducer from "./verification-signup/reducer";
import verificationsReducer from "./verifications/reducer";
import verificationReducerV2 from "./verificationV2/verification-v2-slice";

const combinedReducer = combineReducers({
  user: userReducer,
  verifications: verificationsReducer,
  verification: verificationReducer,
  transactions: transactionsSlice,
  signupVerification: signupVerificationReducer,
  featureFlags: featureFlagsSlice,
  organisation: organisationReducer,
  snackbar: snackbarReducer,
  error: errorReducer,
  breadcrumb: breadcrumbSlice,
  verificationV2: verificationReducerV2,
  [timelineApi.reducerPath]: timelineApi.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const reducer = (state: RootState, action: AnyAction) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
    };
  } else {
    return combinedReducer(state, action);
  }
};

export const makeStore = () =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(timelineApi.middleware),
  });

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store["dispatch"];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootStateStore = ReturnType<Store["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootStateStore, unknown, Action<string>>;

export const wrapper = createWrapper(makeStore, { debug: true });
