import { IncomeTransactionCategory } from "@/constants/IncomeTransactionCategories";
import { CategorizedIncome } from "@/store/verification/helpers";

export enum ActivityType {
  EDUCATION = "education",
  GAP_IN_EMPLOYMENT = "gap_in_employment",
  SELF_EMPLOYMENT = "self_employment",
  EMPLOYMENT = "employment",
  NO_ACTIVITY = "no_activity",
}

export interface Activity {
  id: string;
  type: ActivityType;
  status: ActivityConnectionStatus;
  startDate: string;
  endDate: string | null;
  isCurrent: boolean;
  connections?: ActivityConnection[];
  interacted: boolean;
  verifyViaKonfir: boolean;
  employerOrganisationName: string | null;
  employerOrganisationId: string | null;
  jobTitle: string | null;
  isReceivingBenefits: boolean | null;
  activities: string | null;
  institution: string | null;
  isVerified: boolean | null;
  notes: string | null;
  uniqueTaxpayerReference?: string | null;
  companyName?: string | null;
  companyNumber?: string | null;
  accountingProvider?: string | null;
  accountingAccess?: string | null;
}

export interface ActivityNew {
  id: string;
  type: ActivityType;
  startDate: string;
  endDate: string | null;
  isCurrent: boolean;
  verifyViaKonfir: boolean;
  jobTitle?: string | null;
  isReceivingBenefits?: boolean | null;
  activities?: string | null;
  institution?: string | null;
  employerOrganisationName?: string | null;
  employerOrganisationId?: string | null;
  notes?: string | null;
  uniqueTaxpayerReference?: string | null;
  companyName?: string | null;
  companyNumber?: string | null;
  accountingProvider?: string | null;
  accountingAccess?: string | null;
}

export enum ActivityConnectionProviderType {
  OPEN_BANKING = "open_banking",
  PAYROLL = "payroll_aggregator",
  HMRC = "hmrc",
}

export enum ActivityStatus {
  NOT_CONNECTED = "not_connected",
  CONNECTED = "connected",
}

export enum ActivityConnectionStatus {
  NOT_STARTED = "not_started",
  NOT_CONNECTED = "not_connected",
  CONNECTED = "connected",
}

export enum ActivityConnectionStatusData {
  AVAILABLE = "available",
  NOT_AVAILABLE = "not_available",
}

export enum ActivityConnectionStatusDetails {
  DATA_NOT_FOUND = "data_not_found",
  NO_ACCESS_TO_DATA = "no_access_to_data",
  ACCOUNT_NOT_CONNECTED = "account_not_connected",
  CANDIDATE_ATTEMPTED_TO_CONNECT = "candidate_attempted_to_connect",
  CANDIDATE_DIDNT_ATTEMPT_TO_CONNECT = "candidate_didnt_attempt_to_connect",
  CREDENTIALS_ACCEPTED = "credentials_accepted",
  CREDENTIALS_FAILED_1 = "credentials_failed_1",
  CREDENTIALS_FAILED_2 = "credentials_failed_2",
  CREDENTIALS_FAILED_3 = "credentials_failed_3",
  MFA_ACCEPTED = "mfa_accepted",
  MFA_FAILED_1 = "mfa_failed_1",
  MFA_FAILED_2 = "mfa_failed_2",
  MFA_FAILED_3 = "mfa_failed_3",
}

export enum ProviderAggregator {
  TRUELAYER = "truelayer",
  MISTHO = "mistho",
  WORKREPORT = "workreport",
}

export interface ActivityConnection {
  id?: string;
  correlationId?: string;
  activityId?: string;
  providerType: ActivityConnectionProviderType | null;
  providerName: string | null;
  providerId: string | null;
  providerAggregator?: ProviderAggregator;
  status: ActivityConnectionStatus;
  statusData: ActivityConnectionStatusData;
  statusDetails: ActivityConnectionStatusDetails | null;
  startDate: string | null;
  endDate: string | null;
  updatedAt: string | null;
}

export interface ActivityConnectionPayroll extends ActivityConnection {
  employerName: string | null;
  jobTitle: string | null;
  fullName: string | null;
  nin: string | null;
}

export interface ActivityConnectionOpenBanking extends ActivityConnection {
  spending: TransactionDetails[];
  income: TransactionDetails[] | CategorizedIncome;
  fullName: string | null;
  nin: string | null;
}

export interface TransactionDetails {
  description?: string;
  category?: IncomeTransactionCategory | string;
  totalTransactions: number;
  totalAmount: string;
  currency: string;
  employmentIdentified: boolean;
}

export function isOpenBankingConnection(connection: ActivityConnection): connection is ActivityConnectionOpenBanking {
  return connection.providerType === ActivityConnectionProviderType.OPEN_BANKING;
}

export function isPayrollConnection(connection: ActivityConnection): connection is ActivityConnectionPayroll {
  return connection.providerType === ActivityConnectionProviderType.PAYROLL;
}
