import { AnyAction } from "redux";

import { CLEAR_SNACKBAR, SET_SNACKBAR } from "./actionTypes";
import { MessageType, SnackbarState } from "./types";

const initialState: SnackbarState = {
  snackbarOpen: false,
  snackbarType: MessageType.SUCCESS,
  snackbarMessage: "",
};

const snackbarReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_SNACKBAR:
      const { snackbarOpen, snackbarMessage, snackbarType } = action;
      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage,
      };
    case CLEAR_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};

export default snackbarReducer;
