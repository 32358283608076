import {
  Insight,
  InsightTransformed,
  TimelineActivityInsightEmployerDetails,
  TimelineActivityInsightEvidence,
  TimelineActivityInsightTradingDateMismatch,
  TimelineActivityInsightType,
} from "@/components/Timeline/helpers/insightsAndAlertsUtils";
import { renderDateOrMessage } from "@/utils/date.utils";

export const transformInsights = (insights: Insight[]): InsightTransformed[] => {
  const mergedInsights: InsightTransformed[] = [];

  // Filtering the fraud insights and trading dates insights out because they are handled separately

  const insightsWithoutFraudAndTradingDates = insights.filter(
    (insight) =>
      insight.type !== TimelineActivityInsightType.FRAUD && insight.type !== TimelineActivityInsightType.TRADING_DATES,
  );

  insightsWithoutFraudAndTradingDates.forEach((insight) => {
    const { type, startDate, endDate, evidence } = insight;
    const startDateFormatted = renderDateOrMessage(startDate ?? null);
    const endDateFormatted = renderDateOrMessage(endDate ?? null);
    const doesInsightExist = mergedInsights.some((i) => i.type === type);

    if (!doesInsightExist) {
      mergedInsights.push({
        type,
        evidenceList: [
          {
            name: evidence as TimelineActivityInsightEvidence,
            startDate: startDateFormatted as string,
            endDate: endDateFormatted,
          },
        ],
      });
    }

    if (doesInsightExist) {
      const index = mergedInsights.findIndex((i) => i.type === type);
      mergedInsights[index].evidenceList.push({
        name: evidence as TimelineActivityInsightEvidence,
        startDate: startDateFormatted as string,
        endDate: endDateFormatted,
      });
    }
  });

  return mergedInsights;
};

export const transformFraudInsights = (insights: Insight[]): TimelineActivityInsightEmployerDetails | null => {
  const fraudInsight = insights.find((insight) => insight.type === TimelineActivityInsightType.FRAUD);

  if (!fraudInsight) {
    return null;
  }

  return {
    score: (fraudInsight.evidence as TimelineActivityInsightEmployerDetails).score,
    fraudulentEmployer: (fraudInsight.evidence as TimelineActivityInsightEmployerDetails).fraudulentEmployer,
  };
};

export const transformTradingDatesMismatch = (
  insights: Insight[],
): TimelineActivityInsightTradingDateMismatch | null => {
  const tradingDatesInsight = insights.find((insight) => insight.type === TimelineActivityInsightType.TRADING_DATES);

  if (!tradingDatesInsight) {
    return null;
  }

  return {
    employerName: (tradingDatesInsight.evidence as TimelineActivityInsightTradingDateMismatch).employerName,
    incorporationDate: (tradingDatesInsight.evidence as TimelineActivityInsightTradingDateMismatch).incorporationDate,
    dissolutionDate: (tradingDatesInsight.evidence as TimelineActivityInsightTradingDateMismatch).dissolutionDate,
  };
};
