import { useCallback } from "react";
import { useSelector } from "react-redux";

import Analytics from "utils/analytics";

import { inviteUserToOrganisation, reinviteUserToOrganisation } from "@/api/organization";
import { InviteUserFormData } from "@/components/modals/InviteUserModal";
import { usePagination } from "@/components/Pagination/usePagination";
import { RootState, useAppDispatch } from "@/store";
import {
  deleteOrganisationUserAction,
  getOrganisationAction,
  getOrganisationUsersAction,
  setSettingsMenuOpenAction,
} from "@/store/organisation/actions";
import { OrganisationAdmin, OrganisationUser } from "@/store/organisation/types";
import { setSnackbar } from "@/store/snackbar/actions";
import { MessageType } from "@/store/snackbar/types";
import { Configuration } from "@/types/Organization";

export enum OrganisationType {
  RECRUITER = "recruiter",
  EMPLOYER = "employer",
  BACKGROUND_SCREENING_PROVIDER = "background_screening_provider",
  TENANT_REFERENCING_PROVIDER = "tenant_referencing_provider",
}

export interface UseActiveOrganisation {
  id: string | null;
  name: string | null;
  type: OrganisationType | null;
  configuration: Configuration | null;
  isSettingsMenuOpen: boolean;
  setSettingsMenuOpen: (isSettingsMenuOpen: boolean) => void;
  isTest: boolean;
  isTenantReferencingProvider: boolean;
  isLoading: boolean;
  hasLoaded: boolean;
  loadOrganisationUsers: (id: string) => void;
  organisationUsers: OrganisationUser[];
  organisationAdmins: OrganisationAdmin[];
  inviteUser: (id: string, values: InviteUserFormData) => void;
  reinviteUser: (id: string, email: string) => void;
  removeUser: (id: string, userId: string | number) => void;
  loadOrganisation: (id: string) => void;
  totalUsers: number;
  totalPages: number;
}

export default function useActiveOrganisation(): UseActiveOrganisation {
  const dispatch = useAppDispatch();

  const isSettingsMenuOpen = useSelector((state: RootState) => state.organisation.settingsMenuOpen);

  const organisation = useSelector((state: RootState) => state.organisation.organisationData);
  const organisationUsers = useSelector<RootState, OrganisationUser[]>((state) => state.organisation.organisationUsers);

  const organisationAdmins = useSelector<RootState, OrganisationAdmin[]>(
    (state) => state.organisation.organisationAdmins,
  );
  const isLoading = useSelector((state: RootState) => state.organisation.loading);

  const totalUsers = useSelector<RootState, number>((state) => state.organisation.totalElements);

  const totalUserPages = useSelector<RootState, number>((state) => state.organisation.totalPages);

  const isTenantReferencingProvider = organisation?.type === OrganisationType.TENANT_REFERENCING_PROVIDER;

  const { page, limit } = usePagination({
    totalElements: totalUsers,
    totalPages: totalUserPages,
  });

  const loadOrganisationUsers = useCallback(
    (id: string) => {
      dispatch(getOrganisationUsersAction(id, page, limit));
    },
    [dispatch, page, limit],
  );

  const inviteUser = useCallback(
    (id: string, values: InviteUserFormData) => {
      inviteUserToOrganisation(id, values)
        .then((isInviteSuccess) => {
          if (isInviteSuccess) {
            loadOrganisationUsers(id);
            dispatch(setSnackbar(true, MessageType.SUCCESS, "Invite successfully sent"));
          }
        })
        .catch((e) => dispatch(setSnackbar(true, MessageType.ERROR, e.message)));
    },
    [dispatch, page, limit],
  );

  const resendInvite = useCallback(
    (id: string, email: string) => {
      reinviteUserToOrganisation(id, { email }).then((isInviteSuccess) => {
        if (isInviteSuccess) {
          dispatch(setSnackbar(true, MessageType.SUCCESS, "Invite successfully resent"));
        }
      });
    },
    [dispatch],
  );

  const removeUser = useCallback(
    (id: string, userId: string | number) => {
      dispatch(deleteOrganisationUserAction(id, userId, +page, +limit));
    },
    [dispatch, page, limit],
  );

  const loadOrganisation = useCallback(
    (id: string) => {
      dispatch(getOrganisationAction(id));
    },
    [dispatch],
  );

  const setSettingsMenuOpen = useCallback(
    (isSettingsMenuOpen: boolean) => {
      dispatch(setSettingsMenuOpenAction(isSettingsMenuOpen));
    },
    [dispatch],
  );

  return {
    id: organisation?.id || null,
    configuration: organisation?.configuration || null,
    name: organisation?.name || null,
    type: organisation?.type || OrganisationType.EMPLOYER,
    isTenantReferencingProvider,
    isTest: Analytics.isTestOrganisation(organisation?.name),
    isLoading,
    isSettingsMenuOpen,
    setSettingsMenuOpen,
    hasLoaded: !!organisation,
    organisationUsers,
    organisationAdmins,
    loadOrganisationUsers,
    inviteUser,
    reinviteUser: resendInvite,
    removeUser,
    loadOrganisation,
    totalUsers,
    totalPages: totalUserPages,
  };
}
