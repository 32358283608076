import Router from "next/router";
import { Dispatch } from "redux";

import { ResetPasswordData, UpdatePasswordData } from "../../api/auth";
import * as authApi from "../../api/auth";
import { getUserSession, login, logout } from "../../api/cognito";
import { getCurrentUser } from "../../api/user";
import { SignInValues } from "../../forms/SignIn";
import { identify } from "../../infrastructure/hotjar";
import { LOG_OUT, setItem } from "../../storage";
import { SignUpData, SignUpDataDeprecated } from "../../types/SignUpData";
import { UserRole } from "../../types/UserRole";
import { FAILURE, REQUEST, SUCCESS } from "../../utils/actionType.util";
import Analytics from "../../utils/analytics";
import pruneObject from "../../utils/pruneObject";
import { setSnackbar } from "../snackbar/actions";
import { MessageType } from "../snackbar/types";

import {
  FORGOT_PASSWORD,
  GET_USER,
  RESET_ERROR,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_DEPRECATED,
  UPDATE_PASSWORD,
} from "./actionTypes";

export const resetErrorAction = () => (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(RESET_ERROR) });
};

export const signupDeprecated = async (
  dispatch: Dispatch,
  signupData: SignUpDataDeprecated,
  reCaptcha: string,
  callback?: (userSub: string, orgId: number) => void,
) => {
  try {
    dispatch({ type: REQUEST(SIGN_UP_DEPRECATED) });
    const result = await authApi.registerDeprecated(pruneObject(signupData), {
      headers: {
        "g-recaptcha-token": reCaptcha,
      },
    });
    if (result.status === 201) {
      dispatch({ type: SUCCESS(SIGN_UP_DEPRECATED) });
      identify(signupData.email);
      if (callback) {
        callback(result.data.sub, result.data.organisation.id);
      }
    }
  } catch (error) {
    dispatch({
      type: FAILURE(SIGN_UP_DEPRECATED),
      payload: {
        error: error.response?.data?.message || error.message,
        displayErrorDetails: true,
      },
    });
  }
};

export const signup = async (dispatch: Dispatch, data: SignUpData, reCaptcha: string, callback: () => void) => {
  try {
    dispatch({ type: REQUEST(SIGN_UP) });
    const result = await authApi.register(pruneObject(data), {
      headers: {
        "g-recaptcha-token": reCaptcha,
      },
    });
    if (result.status === 201) {
      dispatch({ type: SUCCESS(SIGN_UP) });
      identify(data.email);
      callback();
    }
  } catch (error) {
    dispatch({
      type: FAILURE(SIGN_UP),
      payload: {
        error: error.response?.data?.message || error.message,
        displayErrorDetails: true,
      },
    });
  }
};

export const signIn = (credentials: SignInValues, callback: () => void) => async (dispatch: Dispatch) => {
  dispatch({
    type: REQUEST(SIGN_IN),
  });
  try {
    const session = await login(credentials);
    const { data } = await getCurrentUser(session.getAccessToken().getJwtToken());
    identify(data.email);
    Analytics.identifyUser(data.id);
    Analytics.profileUser({
      $first_name: data.firstName,
      $last_name: data.lastName,
      $email: data.email,
      $name: `${data.firstName} ${data.lastName}`,
      organisations: data.organisations.map((org) => org.name),
    });
    dispatch({
      type: SUCCESS(SIGN_IN),
      payload: data,
    });
    callback();
  } catch (error) {
    let errorMessage;
    if (error.response) {
      errorMessage = error.response.data.message;
      errorMessage = `${errorMessage[0].toUpperCase()}${errorMessage.slice(1)}`;
    } else {
      errorMessage = error.message;
    }

    dispatch({
      type: FAILURE(SIGN_IN),
      payload: {
        error: errorMessage,
        displayErrorDetails: true,
      },
    });
  }
};

export const forgotPassword = (email: string) => (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(FORGOT_PASSWORD) });
  authApi
    .forgotPassword(email)
    .then(() => {
      dispatch({ type: SUCCESS(FORGOT_PASSWORD) });
      Router.push("/forgot-password/success");
    })
    .catch((err) => {
      dispatch({
        type: FAILURE(FORGOT_PASSWORD),
        payload: {
          error: err.response.data.message,
        },
      });
      dispatch(setSnackbar(true, MessageType.ERROR, err.response.data.message));
    });
};

export const resetPassword = (data: ResetPasswordData) => (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(RESET_PASSWORD) });
  authApi
    .resetPassword(data)
    .then(() => {
      dispatch({ type: SUCCESS(RESET_PASSWORD) });
      Router.push("/reset-password/success");
    })
    .catch((err) => {
      dispatch({
        type: FAILURE(RESET_PASSWORD),
        payload: {
          error: err.response.data.message,
        },
      });
      dispatch(setSnackbar(true, MessageType.ERROR, err.response.data.message));
    });
};

export const updatePasswordAction = (passwordData: UpdatePasswordData) => async (dispatch: Dispatch) => {
  try {
    await authApi.updatePassword(passwordData);
    dispatch({ type: SUCCESS(UPDATE_PASSWORD) });
    const session = await getUserSession();
    const { data } = await getCurrentUser(session.getAccessToken().getJwtToken());
    const currentOrg = data.organisations.find((org) => org.id === data.organisation.id);
    const isAdmin = currentOrg!.roles.includes(UserRole.ADMIN);
    Router.push(`/update-password/success?isAdmin=${isAdmin}`);
    setItem(LOG_OUT, "true");
    logout();
    dispatch({ type: SUCCESS(SIGN_OUT) });
  } catch (err) {
    dispatch(setSnackbar(true, MessageType.ERROR, err.response.data.message));
  }
};

export const loadUser = () => async (dispatch: Dispatch) => {
  dispatch({ type: REQUEST(GET_USER) });
  try {
    const session = await getUserSession();
    const response = await getCurrentUser(session.getAccessToken().getJwtToken());
    const user = response.data;
    identify(user.email);
    dispatch({
      type: SUCCESS(GET_USER),
      payload: {
        ...user,
      },
    });
  } catch (e) {
    dispatch({
      type: FAILURE(GET_USER),
    });
  }
};
