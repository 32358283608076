import { CLEAR_SNACKBAR, SET_SNACKBAR } from "./actionTypes";
import { MessageType } from "./types";

export const setSnackbar = (
  snackbarOpen: boolean,
  snackbarType: MessageType = MessageType.SUCCESS,
  snackbarMessage = "",
) => ({
  type: SET_SNACKBAR,
  snackbarOpen,
  snackbarType,
  snackbarMessage,
});

export const clearSnackbar = () => ({ type: CLEAR_SNACKBAR });
