import axios, { AxiosResponse } from "api/axiosConfig";
import { GetVerificationsResponse } from "store/verifications/types";
import { VerificationTypePeriod } from "types/Verification";

import { TransactionItem, VerifierReview } from "@/hooks/useTimeline/types";
import { FeedbackLoopPayload } from "@/hooks/useVerification";

import { getToken } from "./cognito";

// period verification
export const getPeriodVerification = async (id: string, orgId: string) => {
  const token = await getToken();

  const response = await axios.get(`${process.env.API_URL}/v2/verification/${id}?organisationId=${orgId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const createPeriodVerification = async (
  organisationId: string,
  data: VerificationTypePeriod,
  isSandbox: boolean,
) => {
  const token = await getToken();

  const response = await axios.post(
    `${process.env.API_URL}/verifications?organisationId=${organisationId}`,
    { ...data, periods: data.activities, isSandbox },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const updatePeriodVerification = async (id: string, data: VerificationTypePeriod) => {
  const token = await getToken();

  const response = await axios.put(
    `${process.env.API_URL}/v3/verifications/${id}`,
    { ...data, periods: data.activities },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const submitPeriodVerification = async (id: string, data: { employeeLinkPrefix: string }) => {
  const token = await getToken();
  const response = await axios.post(`${process.env.API_URL}/verifications/${id}/submit`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const cancelPeriodVerification = async (id: string) => {
  const token = await getToken();
  const response = await axios.delete(`${process.env.API_URL}/verifications/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const updateFeedbackLoop = async (
  verificationId: string,
  activityId: string,
  feedbackLoop: FeedbackLoopPayload,
) => {
  const token = await getToken();
  const response = await axios.put(
    `${process.env.API_URL}/verifications/${verificationId}/activities/${activityId}/notes`,
    feedbackLoop,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const updateVerificationReviewStatus = async (verificationId: string) => {
  const token = await getToken();
  const response = await axios.post(
    `${process.env.API_URL}/verifications/${verificationId}/review`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const cancelRetryVerification = async (verificationId: string) => {
  const token = await getToken();
  const response = await axios.post(
    `${process.env.API_URL}/verifications/${verificationId}/cancel-retry`,
    {
      employeeLinkPrefix: `${window.location.origin}/e/`,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const cancelConfirmVerification = async (verificationId: string) => {
  const token = await getToken();
  const response = await axios.post(
    `${process.env.API_URL}/verifications/${verificationId}/cancel-confirm`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response.data;
};

// verification list

export const getVerificationsList = async (
  orgId: string | number,
  role: string,
  page: number,
  size: number,
  fullName: string | string[] | undefined,
  state: string | string[] | undefined,
  from: string | string[] | undefined,
  to: string | string[] | undefined,
  field: string | string[] | undefined,
  direction: string | string[] | undefined,
  reviewStatus: string | string[] | undefined,
): Promise<AxiosResponse<GetVerificationsResponse>> => {
  const token = await getToken();
  const fullNameParam = fullName ? `&fullName=${fullName}` : "";
  const stateParam = state ? `&state=${state}` : "";
  const fromParam = from ? `&from=${from}` : "";
  const toParam = to ? `&to=${to}` : "";
  const sortParam = field ? `&sort=${field},${direction}` : "";
  const reviewedParam = reviewStatus ? `&reviewStatus=${reviewStatus}` : "";

  return await axios.get(
    `${process.env.API_URL}/verifications?organisationId=${orgId}&byRole=${role}&page=${page}&size=${size}${fullNameParam}${stateParam}${fromParam}${toParam}${sortParam}${reviewedParam}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

// CSV upload

export const createVerificationFromCSV = async (orgId: string, fileId: string) => {
  const token = await getToken();

  return axios.post(
    `${process.env.API_URL}/verifications/from-csv?organisationId=${orgId}`,
    { fileId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getEmployersList = async (name: string) => {
  const token = await getToken();
  const encodedName = encodeURIComponent(name);

  return axios.get(`https://api.konfir.com/employers/search?name=${encodedName}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const reviewTransactions = async (
  verificationId: string,
  timelineId: string,
  activityId: string,
  transactions: TransactionItem[],
  verifierReview: VerifierReview,
) => {
  const token = await getToken();

  return axios.post(
    `${process.env.API_URL}/verifications/${verificationId}/timeline/${timelineId}/activities/${activityId}/review-transactions`,
    {
      verifierReview,
      transactions,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};
