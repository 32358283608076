import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  cancelConfirmVerification,
  cancelPeriodVerification,
  cancelRetryVerification,
  createPeriodVerification,
  getEmployersList,
  getPeriodVerification,
  reviewTransactions,
  submitPeriodVerification,
  updateFeedbackLoop,
  updatePeriodVerification,
  updateVerificationReviewStatus,
} from "@/api/verification";
import { EmployerAutocompleteOption } from "@/components/shared/Autocomplete/Autocomplete";
import { TransactionItem, VerifierReview } from "@/hooks/useTimeline/types";
import { FeedbackLoopPayload } from "@/hooks/useVerification";
import { setSnackbar } from "@/store/snackbar/actions";
import { MessageType } from "@/store/snackbar/types";
import { shouldShowFinishReviewingModal, transformIncome } from "@/store/verification/helpers";
import { Activity, ActivityType } from "@/types/Activity";
import { VerificationTypePeriod } from "@/types/Verification";
import Analytics, { VerifierEventName } from "@/utils/analytics";

export interface VerificationSliceState {
  verification: VerificationTypePeriod | null;
  loading: boolean;
  error: string;
  showFinishReviewingModal: boolean | null;
  isSuccessModalOpen: boolean;
  isEmployersListLoading: boolean;
  employersList: EmployerAutocompleteOption[];
}

const initialState: VerificationSliceState = {
  verification: null,
  loading: false,
  showFinishReviewingModal: null,
  isSuccessModalOpen: false,
  isEmployersListLoading: false,
  employersList: [],
  error: "",
};

export const submitVerificationAction = createAsyncThunk(
  "verification-v2/submitVerification",
  async (payload: {
    id: string;
    orgId: string;
    data: VerificationTypePeriod;
    employeeLinkPrefix: string;
    isSandbox: boolean;
    requestType: string;
  }) => {
    if (payload.id) {
      await updatePeriodVerification(payload.id, payload.data);
    } else {
      const data = await createPeriodVerification(payload.orgId, payload.data, payload.isSandbox);
      payload.id = data.id;
    }
    Analytics.sendEvent(VerifierEventName.CLICK_SUBMIT_REQUEST, {
      isSandbox: String(payload.isSandbox),
      requestType: payload.requestType,
    });
    await submitPeriodVerification(payload.id, {
      employeeLinkPrefix: payload.employeeLinkPrefix,
    });
    return await getPeriodVerification(payload.id, payload.orgId);
  },
);

export const cancelVerificationAction = createAsyncThunk(
  "verification-v2/cancelVerification",
  async (payload: { id: string }) => {
    await cancelPeriodVerification(payload.id);
  },
);

export const getVerificationAction = createAsyncThunk(
  "verification-v2/getVerification",
  async (payload: { id: string; orgId: string }) => {
    const request = await getPeriodVerification(payload.id, payload.orgId);
    return transformIncome(request);
  },
);

export const updateVerificationAction = createAsyncThunk(
  "verification-v2/updateVerification",
  async (payload: { id: string; data: VerificationTypePeriod }) => {
    return await updatePeriodVerification(payload.id, payload.data);
  },
);

export const updateFeedbackLoopAction = createAsyncThunk(
  "verification-v2/updateFeedbackLoop",
  async (payload: { verificationId: string; activityId: string; feedbackLoop: FeedbackLoopPayload }) => {
    return await updateFeedbackLoop(payload.verificationId, payload.activityId, payload.feedbackLoop);
  },
);

export const updateVerificationReviewedStatusAction = createAsyncThunk(
  "verification-v2/updateVerificationReviewedStatus",
  async (payload: { verificationId: string }, { dispatch }) => {
    try {
      const response = await updateVerificationReviewStatus(payload.verificationId);
      dispatch(setSnackbar(true, MessageType.SUCCESS, "Review status changed successfully"));
      return response;
    } catch (error) {
      dispatch(setSnackbar(true, MessageType.ERROR, "Something went wrong"));
    }
  },
);

export const getEmployersListAction = createAsyncThunk(
  "verification-v2/getEmployersList",
  async (payload: { name: string }) => {
    return await getEmployersList(payload.name);
  },
);

export const cancelRetryVerificationRequestAction = createAsyncThunk(
  "verification-v2/cancelRetryVerificationRequest",
  async (payload: { verificationId: string; orgId: string }, { dispatch }) => {
    try {
      await cancelRetryVerification(payload.verificationId);
      dispatch(setSnackbar(true, MessageType.SUCCESS, "Retried successfully"));
      dispatch(getVerificationAction({ id: payload.verificationId, orgId: payload.orgId }));
    } catch (err) {
      dispatch(setSnackbar(true, MessageType.ERROR, "Something went wrong"));
    }
  },
);

export const cancelConfirmVerificationRequestAction = createAsyncThunk(
  "verification-v2/cancelConfirmVerificationRequest",
  async (payload: { verificationId: string; orgId: string }, { dispatch }) => {
    try {
      await cancelConfirmVerification(payload.verificationId);
      dispatch(setSnackbar(true, MessageType.SUCCESS, "Confirmed successfully"));
      dispatch(getVerificationAction({ id: payload.verificationId, orgId: payload.orgId }));
    } catch (err) {
      dispatch(setSnackbar(true, MessageType.ERROR, "Something went wrong"));
    }
  },
);

export const reviewTransactionsAction = createAsyncThunk(
  "verification-v2/verifyEmployment",
  async (
    payload: {
      orgId: string;
      verificationId: string;
      timelineId: string;
      activityId: string;
      transactions: TransactionItem[];
      verifierReview: VerifierReview;
      callback: () => void;
      type: ActivityType;
    },
    { dispatch },
  ) => {
    const isEmployment = payload.type === ActivityType.EMPLOYMENT;
    try {
      await reviewTransactions(
        payload.verificationId,
        payload.timelineId,
        payload.activityId,
        payload.transactions,
        payload.verifierReview,
      );
      payload.callback();
      dispatch(
        setSnackbar(
          true,
          MessageType.SUCCESS,
          `${isEmployment ? "Employment" : "Gap in Employment"} reviewed successfully`,
        ),
      );
    } catch (err) {
      dispatch(setSnackbar(true, MessageType.ERROR, "Something went wrong"));
    }
  },
);

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {
    openSuccessModal: (state) => {
      state.isSuccessModalOpen = true;
    },
    closeSuccessModal: (state) => {
      state.isSuccessModalOpen = false;
    },
    resetVerificationAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(submitVerificationAction.pending, (state) => {
      state.isSuccessModalOpen = false;
      state.loading = true;
    });
    builder.addCase(submitVerificationAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessModalOpen = true;
      state.verification = action.payload;
    });
    builder.addCase(submitVerificationAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(cancelVerificationAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(getVerificationAction.pending, (state) => {
      state.loading = true;
      state.isSuccessModalOpen = false;
    });
    builder.addCase(getVerificationAction.fulfilled, (state, action) => {
      state.loading = false;
      state.isSuccessModalOpen = false;
      state.verification = action.payload;
    });
    builder.addCase(getVerificationAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(updateVerificationAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVerificationAction.fulfilled, (state, action) => {
      state.loading = false;
      state.verification = action.payload;
    });
    builder.addCase(updateVerificationAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(updateFeedbackLoopAction.fulfilled, (state, action) => {
      const activityFound = (<Activity[]>state.verification?.activities).find(
        (activity) => activity.id === action.payload.activityId,
      );
      const activityUpdated = { ...activityFound, ...action.payload };
      const activities: Activity[] = (<Activity[]>state.verification?.activities).map((activity) =>
        activity.id === activityUpdated.id ? activityUpdated : activity,
      );
      state.loading = false;
      state.showFinishReviewingModal = shouldShowFinishReviewingModal(activities);
      state.verification = {
        ...state.verification,
        activities,
      } as VerificationTypePeriod;
    });
    builder.addCase(getEmployersListAction.pending, (state) => {
      state.isEmployersListLoading = true;
    });
    builder.addCase(getEmployersListAction.fulfilled, (state, action) => {
      state.isEmployersListLoading = false;
      state.employersList = action.payload.data;
    });
    builder.addCase(getEmployersListAction.rejected, (state, action) => {
      state.isEmployersListLoading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(updateVerificationReviewedStatusAction.fulfilled, (state, action) => {
      state.loading = false;
      state.verification = {
        ...state.verification,
        reviewed: action.payload,
      } as VerificationTypePeriod;
    });
    builder.addCase(updateVerificationReviewedStatusAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "";
    });
    builder.addCase(reviewTransactionsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reviewTransactionsAction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(reviewTransactionsAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "";
    });
  },
});

export const { openSuccessModal, closeSuccessModal, resetVerificationAction } = verificationSlice.actions;

export default verificationSlice.reducer;
