import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";

interface UseCookieConsent {
  isPreferenceSaved: boolean;
  hasPerformanceConsent: boolean;
  save: (performanceConsent: boolean) => void;
  rejectAll: () => void;
  reset: () => void;
}

export default function useCookieConsent(): UseCookieConsent {
  const PERFORMANCE_CONSENT_KEY = "cookie-consent-performance";
  const PREFERENCES_SAVED_CONSENT_KEY = "cookie-consent-preferences-saved-v2";

  // Avoid showing the cookie notice on hard refresh
  const [isPreferenceSaved, setPreferenceSaved] = useState(true);

  useEffect(() => {
    setPreferenceSaved(Cookies.get(PREFERENCES_SAVED_CONSENT_KEY) === "true");
  });

  const hasPerformanceConsent = Cookies.get(PERFORMANCE_CONSENT_KEY) === "true";

  const save = useCallback((performanceConsent: boolean) => {
    Cookies.set(PERFORMANCE_CONSENT_KEY, String(performanceConsent));
    Cookies.set(PREFERENCES_SAVED_CONSENT_KEY, "true");
    setPreferenceSaved(true);
  }, []);

  const rejectAll = useCallback(() => {
    Cookies.set(PERFORMANCE_CONSENT_KEY, "false");
    Cookies.set(PREFERENCES_SAVED_CONSENT_KEY, "true");
    setPreferenceSaved(true);
  }, []);

  const reset = () => {
    Cookies.remove(PERFORMANCE_CONSENT_KEY);
    Cookies.remove(PREFERENCES_SAVED_CONSENT_KEY);
    setPreferenceSaved(false);
  };

  return {
    isPreferenceSaved,
    hasPerformanceConsent,
    save,
    rejectAll,
    reset,
  };
}
