import { IncomeTransactionCategory } from "constants/IncomeTransactionCategories";
import {
  Activity,
  ActivityConnectionOpenBanking,
  ActivityConnectionProviderType,
  ActivityConnectionStatus,
  TransactionDetails,
} from "types/Activity";
import { VerificationTypePeriod } from "types/Verification";

type CategorisedTransactions = { [k in IncomeTransactionCategory | string]: TransactionDetails[] };

const categories = Object.values(IncomeTransactionCategory);
const isOneOfTheCategories = (category: string) => categories.includes(category as IncomeTransactionCategory);

export const categoriseIncome = (income: TransactionDetails[]): CategorisedTransactions => {
  const initialCategorisedTransactions: CategorisedTransactions = categories.reduce(
    (previousValue, currentValue: IncomeTransactionCategory) => {
      return { ...previousValue, [currentValue]: [] };
    },
    {},
  );

  return income.reduce((categorisedTransactions: CategorisedTransactions, transaction: TransactionDetails) => {
    if (!transaction.category || !isOneOfTheCategories(transaction.category)) {
      return {
        ...categorisedTransactions,
        [IncomeTransactionCategory.IRRELEVANT]:
          categorisedTransactions[IncomeTransactionCategory.IRRELEVANT].concat(transaction),
      };
    }

    if (transaction.category === IncomeTransactionCategory.EMPLOYER_NAME_MATCH) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { employer_name_match, ...rest } = categorisedTransactions;
      return {
        ...rest,
        [IncomeTransactionCategory.EMPLOYMENT]:
          categorisedTransactions[IncomeTransactionCategory.EMPLOYMENT].concat(transaction),
      };
    } else {
      return {
        ...categorisedTransactions,
        [transaction.category]:
          categorisedTransactions[transaction.category as IncomeTransactionCategory].concat(transaction),
      };
    }
  }, initialCategorisedTransactions);
};

export type CategorizedIncome = ReturnType<typeof categoriseIncome>;

export const transformIncome = (verification: VerificationTypePeriod) => {
  return {
    ...verification,
    activities: verification.activities.map((activity: Activity) => {
      return {
        ...activity,
        connections: activity?.connections?.map((connection: ActivityConnectionOpenBanking) => {
          const { income, providerType } = connection;
          const uncategorisedIncome = income as TransactionDetails[];
          if (
            uncategorisedIncome &&
            uncategorisedIncome.length > 0 &&
            providerType === ActivityConnectionProviderType.OPEN_BANKING
          ) {
            return {
              ...connection,
              income: categoriseIncome(uncategorisedIncome),
            };
          } else {
            return connection;
          }
        }),
      };
    }),
  };
};

export const shouldShowFinishReviewingModal = (activities: Activity[]) => {
  if (!activities || activities.length === 0) {
    return false;
  }
  const connectedActivities = activities.filter((activity) => activity.status === ActivityConnectionStatus.CONNECTED);
  return connectedActivities.some((activity) => {
    return activity.notes === null && activity.isVerified === null;
  });
};
