import { useRouter } from "next/router";
import { ChangeEvent } from "react";

export interface UsePagination {
  page: number;
  limit: number;
  itemsPerPageOptions: number[];
  totalElements: number;
  totalPages: number;
  setPage: (data: { selected: number }) => void;
  setLimit: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  getNumberOfItems: (label: string) => string | undefined;
}

interface UsePaginationProps {
  totalElements: number;
  totalPages: number;
}

export const usePagination = ({ totalElements, totalPages }: UsePaginationProps): UsePagination => {
  const router = useRouter();
  const { page, limit } = router.query;
  const itemsPerPageOptions = router.pathname.includes("transactions") ? [20, 50, 100] : [10, 30, 50];

  const getNumberOfItems = (label: string) => {
    if (Number(page) === totalPages) {
      return `Showing ${totalElements} of ${totalElements} ${label}`;
    }

    if (totalElements === 0) {
      return;
    }

    return `Showing ${Number(page) * Number(limit || 10)} of ${totalElements} ${label}`;
  };

  const setPage = (data: { selected: number }) => {
    router.push(
      {
        href: router.pathname,
        query: {
          ...router.query,
          page: data.selected + 1,
          limit,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  const setLimit = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    router.push(
      {
        href: router.pathname,
        query: {
          ...router.query,
          page: 1,
          limit: event.target.value,
        },
      },
      undefined,
      { shallow: true },
    );
  };

  return {
    page: page ? Number(page) : 1,
    limit: limit ? Number(limit) : 10,
    setPage,
    setLimit,
    itemsPerPageOptions,
    totalElements,
    totalPages,
    getNumberOfItems,
  };
};
