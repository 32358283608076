export enum IncomeTransactionCategory {
  EMPLOYMENT = "employment",
  EMPLOYER_NAME_MATCH = "employer_name_match",
  BENEFITS = "benefits",
  UNCATEGORISED = "uncategorised",
  TAX = "tax",
  WEALTH = "wealth",
  LOAN = "loan",
  ENTERTAINMENT = "entertainment",
  REFUND = "refund",
  INTEREST = "interest",
  PERSONAL = "personal",
  IRRELEVANT = "irrelevant",
}
