import { AnyAction } from "redux";

import { FAILURE, REQUEST, SUCCESS } from "../../utils/actionType.util";
import { SIGN_OUT } from "../user/actionTypes";

import { GET_VERIFICATIONS_LIST } from "./actionTypes";
import { VerificationsStore } from "./types";

const initialVerificationsState: VerificationsStore = {
  loading: false,
  error: null,
  verifications: [],
  page: 1,
  limit: 10,
  totalElements: 0,
  totalPages: 0,
  counts: {
    total: 0,
    reviewed: 0,
    pendingReview: 0,
    cancelled: 0,
  },
};

const verificationsReducer = (state = initialVerificationsState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST(GET_VERIFICATIONS_LIST):
      return {
        ...state,
        loading: true,
      };
    case SUCCESS(GET_VERIFICATIONS_LIST):
      return {
        ...state,
        loading: false,
        verifications: action.payload.content,
        page: action.payload.page,
        limit: action.payload.limit,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        counts: action.payload.counts || state.counts,
      };
    case FAILURE(GET_VERIFICATIONS_LIST):
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SUCCESS(SIGN_OUT):
      return initialVerificationsState;
    default:
      return state;
  }
};

export default verificationsReducer;
