export const REDIRECT_TO = "REDIRECT_TO";
export const LOG_OUT = "LOG_OUT";
export const PREFILL = "PREFILL";
export const PREFILL_EMAIL = "PREFILL_EMAIL";
export const USER_ROLE = "USER_ROLE";

export function setItem(key: string, value: string) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem(key, value);
  }
}

export function getItem(key: string): string | null {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(key);
  }
  return null;
}

export function removeItem(key: string) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(key);
  }
}

export function clearRedirect() {
  if (typeof localStorage !== "undefined") {
    const isLoggedOut = localStorage.getItem(LOG_OUT);
    if (isLoggedOut === "true") {
      localStorage.removeItem(REDIRECT_TO);
      localStorage.removeItem(LOG_OUT);
    }
  }
}
