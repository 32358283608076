import {
  transformFraudInsights,
  transformInsights,
  transformTradingDatesMismatch,
} from "@/components/Timeline/helpers/transformInsights";
import { Timeline, TimelineActivityTransformed, TimelineTransformed } from "@/hooks/useTimeline/types";

import baseApi, { VerifierEndpointNames } from "./base";

export const timelineApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    [VerifierEndpointNames.GET_TIMELINE]: builder.query<
      TimelineTransformed,
      { verificationId: string; organisationId: string }
    >({
      query: (args) => `/verifications/${args.verificationId}/timeline?organisationId=${args.organisationId}`,
      transformResponse: (response: Timeline): TimelineTransformed | Promise<TimelineTransformed> => {
        return {
          ...response,
          activities: response.activities.map(
            (activity): TimelineActivityTransformed => ({
              ...activity,
              insights: activity.insights ? transformInsights(activity.insights) : null,
              fraud: activity.insights ? transformFraudInsights(activity.insights) : null,
              tradingDatesMismatch: activity.insights ? transformTradingDatesMismatch(activity.insights) : null,
            }),
          ),
        };
      },
    }),
    [VerifierEndpointNames.UPDATE_TIMELINE_REVIEW_STATUS]: builder.mutation({
      query: (args: { verificationId: string }) => ({
        url: `${process.env.API_URL}/verifications/${args.verificationId}/review`,
        method: "POST",
        body: {},
      }),
    }),
    [VerifierEndpointNames.REVIEW_FRAUD_INSIGHTS]: builder.mutation({
      query: (args: {
        organisationId: string;
        verificationId: string;
        timelineId: string;
        activityId: string;
        verifierReviewedAsUseful: boolean;
      }) => ({
        url: `${process.env.API_URL}/verifications/${args.verificationId}/timeline/${args.timelineId}/activities/${args.activityId}/review/fraud-insight`,
        method: "POST",
        body: {
          verifierReviewedAsUseful: args.verifierReviewedAsUseful,
        },
      }),
    }),
  }),
});

export const { useGetTimelineQuery, useUpdateTimelineReviewStatusMutation, useReviewFraudInsightsMutation } =
  timelineApi;
