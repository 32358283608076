import { common, red } from "@material-ui/core/colors";
import { createTheme, darken } from "@material-ui/core/styles";
import { AlertClassKey } from "@material-ui/lab/Alert";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiPickersBasePicker: unknown;
    MuiPickersYearSelection: unknown;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#141E49",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: "#141E49",
      secondary: "rgb(114, 119, 137)",
    },
  },
  typography: {
    fontFamily: ["'Poppins'", "sans-serif"].join(","),
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 10,
      },
    },
    MuiAlert: {
      filledError: {
        backgroundColor: "#E24D59",
      },
    },
    MuiTypography: {
      root: {
        letterSpacing: 0,
      },
      colorPrimary: {
        color: "#141E49",
      },
      colorSecondary: {
        color: "#727789",
      },
      colorTextSecondary: {
        color: "rgba(102, 108, 136, 1)",
      },
      h1: {
        fontSize: 28,
        fontWeight: 500,
        lineHeight: 1.5,
        "@media (min-width:600px)": {
          fontSize: 36,
        },
      },
      h2: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: 1.5,
      },
      h3: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1.5,
      },
      h4: {
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
      },
      h5: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 12,
        lineHeight: 1.5,
        fontWeight: 500,
      },

      displayBlock: {
        fontSize: 14,
        lineHeight: 2,
        fontWeight: 500,
        "@media (min-width:600px)": {
          fontSize: 16,
        },
      },
      paragraph: {
        marginBottom: "24px",
      },
      gutterBottom: {
        marginBottom: "12px",
      },
    },

    MuiLinearProgress: {
      root: {
        height: 6,
        borderRadius: 3,
      },
      colorPrimary: {
        backgroundColor: "rgb(243, 243, 243)",
      },
      bar: {
        borderRadius: 3,
      },
      barColorPrimary: {
        backgroundColor: "rgb(172, 177, 180)",
      },
    },
    MuiButton: {
      contained: {
        padding: "0.5rem 1rem",
        textWrap: "nowrap",
        backgroundColor: "rgba(15, 127, 159, 1)",
        border: "1px solid rgba(15, 127, 159, 1)",
        boxShadow: "none !important",

        "&.Mui-disabled": {
          opacity: 0.8,
          border: "1px solid transparent",
        },

        "& .MuiButton-label": {
          fontSize: 18,
          lineHeight: "1.5",
          fontWeight: 600,
          letterSpacing: 0,
          color: "#FFFFFF",
          textTransform: "none",
          borderRadius: 4,
          transition: "all .3s ease",
        },

        "&:hover": {
          backgroundColor: darken("rgba(15, 127, 159, 1)", 0.2),
          border: "1px solid rgba(15, 127, 159, 1)",
        },
      },
      outlined: {
        backgroundColor: "#fff",
        padding: "0.5rem 1rem",
        boxShadow: "none",
        border: "1px solid rgba(178, 181, 199, 1)",
        transition: "all .3s ease",

        "&.Mui-disabled": {
          opacity: 0.8,
          border: "1px solid transparent",
        },

        "& .MuiButton-label": {
          fontSize: 18,
          lineHeight: "1.5",
          fontWeight: 600,
          letterSpacing: 0,
          color: "rgba(102, 108, 136, 1)",
          textTransform: "none",
          borderRadius: 4,
          transition: "all .3s ease",
        },

        "&:hover": {
          backgroundColor: "rgba(214, 215, 225, 1)",
          borderColor: "rgba(102, 108, 136, 1)",
          color: "rgba(69, 73, 95, 1)",
          transition: "all .3s ease",
        },
      },
      sizeSmall: {
        height: "48px",
        display: "flex",
        padding: "1px",

        "& .MuiButton-label": {
          fontSize: "16px",
          lineHeight: "24px",
          height: "46px",
          "&:hover": {
            backgroundColor: "rgba(15, 127, 159, 1)",
            color: "#fff",
          },
        },

        "&:focus": {
          opacity: 0.5,
        },
      },
    },
    MuiButtonGroup: {
      root: {
        background: "#F6F8F9",
        padding: "0.25rem",
      },
      groupedHorizontal: {
        background: "transparent",
        lineHeight: "20px",
        border: "0 !important",
        borderRadius: "0.25rem !important",
        padding: 0,
        "&:hover": {
          border: "0 !important",
          backgroundColor: "#F6F8F9",
        },

        "& .MuiButton-label": {
          background: "transparent",
          fontSize: "1rem",
          fontWeight: 500,
          color: "#141E49",
          letterSpacing: 0,
          textTransform: "none",
          padding: "1rem 1.25rem",
          lineHeight: "1.25rem",

          "&:hover": {
            color: "rgb(20, 30, 73)",
            border: "0 !important",
          },
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiListItem: {
      button: {
        color: "#FFFFFF",
        opacity: 0.3,
        paddingBlock: "6px",

        "&$selected": {
          opacity: 1,
          backgroundColor: "rgba(54, 197, 236, 0.1)",

          "&:hover": {
            backgroundColor: "rgba(54, 197, 236, 0.09)",
          },
        },
        root: {
          paddingBlock: "6px",

          "&$selected": {
            color: "#fff",
            backgroundColor: "#141E49",

            "&:hover": {
              backgroundColor: "rgba(54, 197, 236, 0.09)",
            },
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgba(53, 55, 69, 1)",
        height: 50,
        paddingBlock: "6px",
        borderRadius: 5,
        opacity: 1,
        transition: "all .3s ease",

        "&$selected": {
          color: "#fff",
          backgroundColor: "#141E49",
          pointerEvents: "none",
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(20, 30, 73, 0.15)",
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: "rgb(172, 177, 180)",
      },
      colorSecondary: {
        color: common.white,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "transparent",
        color: "#212121",
      },
      root: {
        height: 96,
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "rgb(23, 31, 73)",
        },
        "&$disabled": {
          color: "rgb(23, 31, 73)",
        },
        "&$error": {
          color: "rgba(220, 38, 38, 1)",
        },
      },
      asterisk: {
        color: "rgb(226, 77, 89)",
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          color: "rgba(220, 38, 38, 1)",
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        "& .MuiOutlinedInput-input::placeholder": {
          color: "rgba(102, 108, 136, 1)",
          opacity: 1,
        },
        "&$focused $notchedOutline": {
          fontSize: 14,
          fontWeight: 500,
          border: "1px solid rgb(53, 196, 236) !important",
          boxShadow: "0px 0px 5px 5px rgba(53, 196, 236, 0.1)",
        },
        "&$disabled $notchedOutline": {
          border: "none !important",
          boxShadow: "none",
        },
        "&$error $notchedOutline": {
          border: "1px solid rgba(220, 38, 38, 1) !important",
          boxShadow: "none",
          background: "rgba(226, 77, 89, 0.1)",
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, -30px) !important",
        color: "rgba(69, 73, 95, 1)",
      },
      outlined: {
        fontSize: 16,
        fontWeight: "normal",
        letterSpacing: 0,
        pointerEvents: "auto",
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          color: "rgb(23, 31, 73)",
          backgroundColor: "rgb(246, 248, 249)",
        },
      },
      input: {
        "&::placeholder": {
          color: "#000000",
        },
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: 20,
      },
      label: {
        "&$active": {
          color: "#FFFFFF",
        },
        "&$completed": {
          color: "#FFFFFF",
        },
      },
    },
    MuiStepConnector: {
      vertical: {
        marginLeft: 14,
        padding: 0,

        "&$disabled": {
          "& span": {
            background: "rgb(67, 75, 109)",
          },
        },
      },
      lineVertical: {
        height: 70,
        background: "linear-gradient(45deg, rgb(101, 187, 71) 0%, rgb(54, 197, 236) 100%)",
        width: 2,
        borderColor: "transparent",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: 280,
        minWidth: 280,
      },
    },
    MuiPickersYearSelection: {
      container: {
        height: 280,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: 12,
        minHeight: 192,
      },
    },
    MuiTabs: {
      root: {
        minHeight: 60,
        background: "#F6F8F9",
        borderRadius: "4px",
        padding: "4px",
      },
      indicator: {
        display: "none",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#141E49",
      },
      root: {
        height: 52,
        padding: 0,
        borderRadius: "4px",
        minHeight: "auto",
        "&$selected, &:hover": {
          background: "#fff",
        },
      },
      wrapper: {
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: 1.5,
        textTransform: "none",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        margin: "0 0 32px 0",
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 1.5,
      },
    },
  },
});

export default theme;
