import axios from "api/axiosConfig";

import { getToken } from "./cognito";

export enum TransactionType {
  SPENDING = "spending",
  INCOME = "income",
}

export const getTransactions = async (
  requestId: string,
  activityId: string,
  type: TransactionType,
  page?: number,
  limit?: number,
  merchant?: string,
  accountProviderId?: string,
  amount?: string,
  field?: string,
  direction?: string,
) => {
  const token = await getToken();
  const apiUrl = process.env.API_URL;
  const merchantParam = merchant ? `&merchant=${merchant}` : "";
  const accountProviderIdParam = accountProviderId ? `&accountProviderName=${accountProviderId}` : "";
  const amountParam = amount ? `&amount=${amount}` : "";
  const sortParam = field && direction ? `&sort=${field},${direction}` : "";
  const requestUrl =
    page && limit
      ? `${apiUrl}/verifications/${requestId}/activities/${activityId}/transactions?type=${type}&page=${page}&size=${limit}${merchantParam}${accountProviderIdParam}${amountParam}${sortParam}`
      : `${apiUrl}/verifications/${requestId}/activities/${activityId}/transactions?type=${type}`;
  const response = await axios.get(requestUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
