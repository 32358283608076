import React, { useEffect } from "react";

import useActiveOrganisation from "hooks/useActiveOrganisation";
import useCurrentUser from "hooks/useCurrentUser";
import useFeatureFlags from "hooks/useFeatureFlags";

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
}

const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({ children }) => {
  const { init, identify, isReady, isLDClientAvailable } = useFeatureFlags();
  const { isLoggedIn, id, firstName, lastName, email } = useCurrentUser();
  const activeOrganisation = useActiveOrganisation();

  useEffect(() => {
    if (!isReady && isLDClientAvailable) {
      init();
    }
  }, [isReady, isLDClientAvailable]);

  useEffect(() => {
    if (isLoggedIn && isReady && activeOrganisation.id) {
      identify(
        {
          id: id!,
          firstName: firstName!,
          lastName: lastName!,
          email: email!,
        },
        activeOrganisation.id,
      );
    }
  }, [isLoggedIn, isReady, activeOrganisation.id]);

  return <>{children}</>;
};

export default FeatureFlagsProvider;
