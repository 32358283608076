import axios, { AxiosRequestConfig } from "api/axiosConfig";

import { SignUpData, SignUpDataDeprecated } from "../types/SignUpData";

import { getToken } from "./cognito";

export type ResetPasswordData = {
  email: string;
  password: string;
  code: string;
};

export type VerifyUserResponse = {
  email?: string;
  employeeFullName?: string;
};

export type UpdatePasswordData = {
  currentPassword: string;
  newPassword: string;
};

export function registerDeprecated(data: SignUpDataDeprecated, config: AxiosRequestConfig = {}) {
  return axios.post(`${process.env.API_URL}/users`, data, config);
}

export const register = (data: SignUpData, config: AxiosRequestConfig = {}) => {
  return axios.post(`${process.env.API_URL}/auth/register`, data, config);
};

export function verifyCode(code: string | string[]) {
  return axios.post<VerifyUserResponse>(`${process.env.API_URL}/v2/users/confirm`, { code });
}

export function resendConfirmEmail(email: string, callback: string) {
  return axios.post(`${process.env.API_URL}/v2/users/reconfirm`, {
    email,
    callback,
  });
}

export function forgotPassword(email: string) {
  return axios.post(`${process.env.API_URL}/forgot-password`, {
    email,
    callbackUrl: `${window.location.origin}/reset-password`,
  });
}

export function resetPassword(data: ResetPasswordData) {
  return axios.post(`${process.env.API_URL}/reset-password`, data);
}

export async function updatePassword(data: UpdatePasswordData) {
  const token = await getToken();
  return axios.put(`${process.env.API_URL}/user/password`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
