import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TransactionItem } from "hooks/useActivityTransactions";

import { getTransactions, TransactionType } from "../../api/transactions";

export interface TransactionsState {
  meta: {
    type: string;
    description: string;
  } | null;
  providers: { id: string; name: string }[];
  transactions: TransactionItem[] | null;
  loading: boolean;
  error: string | undefined;
  page: number;
  limit: number;
  totalElements: number;
  totalPages: number;
}

const initialTransactionsState: TransactionsState = {
  meta: null,
  providers: [],
  transactions: null,
  loading: false,
  error: "",
  page: 1,
  limit: 10,
  totalElements: 0,
  totalPages: 0,
};

export const getTransactionsAction = createAsyncThunk(
  "transactions/getTransactions",
  async (payload: {
    requestId: string;
    activityId: string;
    type: TransactionType;
    page: number;
    limit: number;
    merchant?: string;
    accountProviderId?: string;
    amount?: string;
    field?: string;
    direction?: string;
  }) => {
    return await getTransactions(
      payload.requestId,
      payload.activityId,
      payload.type,
      payload.page,
      payload.limit,
      payload.merchant ? encodeURIComponent(payload.merchant as string) : undefined,
      payload.accountProviderId,
      payload.amount,
      payload.field,
      payload.direction,
    );
  },
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialTransactionsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTransactionsAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTransactionsAction.fulfilled, (state, action) => {
      state.transactions = action.payload.content;
      state.meta = action.payload.meta.activity;
      state.providers = action.payload.meta.providers;
      state.loading = false;
      state.page = action.payload.page;
      state.limit = action.payload.limit;
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
    });
    builder.addCase(getTransactionsAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default transactionsSlice.reducer;
