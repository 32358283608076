import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";

import { isDomain } from "./domain";

interface UserProfile {
  $name: string;
  $first_name: string;
  $last_name: string;
  $email: string;
  organisations: string[];
}

enum EventCategory {
  TIMELINE = "timeline",
  PAYROLL = "payroll",
  HMRC = "hmrc",
  VERIFIER_VERIFICATION_DETAIL = "verifier_verification_detail",
  REQUEST_LIST = "request_list",
  CREATE_REQUEST = "create_request",
  SETTINGS = "settings",
  REQUEST_DETAIL = "request_detail",
  TIMELINE_VIEW = "timeline_view",
  UPLOAD_CSV = "upload_csv",
}

export enum VerifierEventName {
  PAGEVIEW_SETTINGS = "pageview_settings",
  PAGEVIEW_REQUEST_LIST = "pageview_request_list",
  PAGEVIEW_REQUEST_DETAIL = "pageview_request_detail",
  PAGEVIEW_INCOME_TRANSACTIONS = "pageview_income_transactions",
  PAGEVIEW_SPENDING_TRANSACTIONS = "pageview_spending_transactions",
  PAGEVIEW_CREATE_REQUEST = "pageview_create_request",
  CLICK_CREATE_REQUEST = "verifier_click_create_request",
  CLICK_DOWNLOAD_PDF = "verifier_click_download_pdf",
  CLICK_SUBMIT_REQUEST = "verifier_click_submit_request",
  CLICK_CANCEL_REQUEST = "verifier_click_cancel_request",
  CLICK_BACK = "verifier_click_back",
  CLICK_ADD_ACTIVITY = "verifier_click_add_activity",
  CLICK_EDIT_ACTIVITY = "verifier_click_edit_activity",
  CLICK_REMOVE_ACTIVITY = "verifier_click_remove_activity",
  CLICK_SAVE_ACTIVITY = "verifier_click_save_activity",
  CLICK_CLOSE_ACTIVITY = "verifier_click_close_activity",
  CLICK_VERIFY_ACTIVITY = "verifier_click_verify_activity",
  CLICK_MARK_AS_REVIEWED_FEEDBACK_LOOP_INCOMPLETE = "verifier_click_mark_as_reviewed_feedback_loop_incomplete",
  CLICK_MARK_AS_REVIEWED_FEEDBACK_LOOP_COMPLETE = "verifier_click_mark_as_reviewed_feedback_loop_complete",
  CLICK_OPEN_REQUEST = "verifier_click_open_request",
  CLICK_FEEDBACK_LOOP_IS_VERIFIED_BUTTON = "verifier_click_feedback_loop_is_verified_button",
  UPDATE_FEEDBACK_LOOP_NOTES = "verifier_update_feedback_loop_notes",
  UPLOAD_CSV_WRONG_MIME_TYPE = "verifier_upload_csv_wrong_mime_type",
  UPLOAD_CSV_FILE_TOO_LARGE = "verifier_upload_csv_file_too_large",
  UPLOAD_CSV_MISSING_REFERENCE_ID = "verifier_upload_csv_missing_reference_id",
  UPLOAD_CSV_MISSING_REQUIRED_COLUMNS = "verifier_upload_csv_missing_required_columns",
  CLICK_INCOME_TRANSACTIONS_ACCORDION = "click_income_transactions_accordion",
  CLICK_INCOME_TRANSACTIONS_VIEW_ALL = "click_income_transactions_view_all",
  CLICK_RETRY_CANCELLATION = "click_retry_cancellation",
  CLICK_CONFIRM_CANCELLATION = "click_confirm_cancellation",
  CLICK_TIMELINE_TAB = "click_timeline_tab",
  CLICK_ACTIVITY_TAB = "click_activity_tab",
  CLICK_TIMELINE_DROPDOWN = "click_timeline_dropdown",
  CLICK_VERIFY_TRANSACTIONS = "click_verify_transactions",
  CLICK_UNABLE_TO_VERIFY_TRANSACTIONS = "click_unable_to_verify_transactions",
  FRAUD_INSIGHT_USEFUL = "fraud_insight_useful",
  FRAUD_INSIGHT_NOT_USEFUL = "fraud_insight_not_useful",
}

const getCategory = (event: EventName) => {
  switch (event) {
    case VerifierEventName.CLICK_VERIFY_TRANSACTIONS:
    case VerifierEventName.CLICK_UNABLE_TO_VERIFY_TRANSACTIONS:
    case VerifierEventName.FRAUD_INSIGHT_NOT_USEFUL:
    case VerifierEventName.FRAUD_INSIGHT_USEFUL:
      return EventCategory.TIMELINE;

    case VerifierEventName.PAGEVIEW_REQUEST_LIST:
      return EventCategory.REQUEST_LIST;

    case VerifierEventName.PAGEVIEW_SETTINGS:
      return EventCategory.SETTINGS;

    case VerifierEventName.PAGEVIEW_REQUEST_DETAIL:
    case VerifierEventName.PAGEVIEW_INCOME_TRANSACTIONS:
    case VerifierEventName.PAGEVIEW_SPENDING_TRANSACTIONS:
    case VerifierEventName.CLICK_FEEDBACK_LOOP_IS_VERIFIED_BUTTON:
    case VerifierEventName.UPDATE_FEEDBACK_LOOP_NOTES:
    case VerifierEventName.CLICK_INCOME_TRANSACTIONS_ACCORDION:
    case VerifierEventName.CLICK_INCOME_TRANSACTIONS_VIEW_ALL:
    case VerifierEventName.CLICK_MARK_AS_REVIEWED_FEEDBACK_LOOP_INCOMPLETE:
    case VerifierEventName.CLICK_MARK_AS_REVIEWED_FEEDBACK_LOOP_COMPLETE:
    case VerifierEventName.CLICK_RETRY_CANCELLATION:
    case VerifierEventName.CLICK_CONFIRM_CANCELLATION:
    case VerifierEventName.CLICK_TIMELINE_TAB:
    case VerifierEventName.CLICK_ACTIVITY_TAB:
      return EventCategory.REQUEST_DETAIL;

    case VerifierEventName.PAGEVIEW_CREATE_REQUEST:
    case VerifierEventName.CLICK_CREATE_REQUEST:
    case VerifierEventName.CLICK_SUBMIT_REQUEST:
    case VerifierEventName.CLICK_CANCEL_REQUEST:
    case VerifierEventName.CLICK_BACK:
    case VerifierEventName.CLICK_ADD_ACTIVITY:
    case VerifierEventName.CLICK_EDIT_ACTIVITY:
    case VerifierEventName.CLICK_REMOVE_ACTIVITY:
    case VerifierEventName.CLICK_SAVE_ACTIVITY:
    case VerifierEventName.CLICK_CLOSE_ACTIVITY:
    case VerifierEventName.CLICK_VERIFY_ACTIVITY:
    case VerifierEventName.CLICK_OPEN_REQUEST:
      return EventCategory.CREATE_REQUEST;

    case VerifierEventName.CLICK_DOWNLOAD_PDF:
      return EventCategory.VERIFIER_VERIFICATION_DETAIL;

    case VerifierEventName.UPLOAD_CSV_WRONG_MIME_TYPE:
    case VerifierEventName.UPLOAD_CSV_FILE_TOO_LARGE:
    case VerifierEventName.UPLOAD_CSV_MISSING_REFERENCE_ID:
    case VerifierEventName.UPLOAD_CSV_MISSING_REQUIRED_COLUMNS:
      return EventCategory.UPLOAD_CSV;

    case VerifierEventName.CLICK_TIMELINE_DROPDOWN:
      return EventCategory.TIMELINE_VIEW;

    default:
      return "undefined";
  }
};

export enum EVENT_PROPERTIES {
  IS_TEST_VERIFICATION = "is_test_verification",
  VERIFIER_ORGANISATION_NAME = "verifier_organisation_name",
  VERIFICATION_ID = "verification_id",
}

//TODO: K in EVENT_PROPERTIES
export type CustomProperties = {
  [K in string]: string;
};

type EventName = VerifierEventName;

export default class Analytics {
  private static isPerformanceCookieAccepted = () => Cookies.get("cookie-consent-performance") === "true";

  public static isTestOrganisation(organisationName: string): boolean {
    if (!organisationName) {
      return false;
    }
    return organisationName.startsWith("0TestCompany");
  }

  private static isTestVerification(verifierOrganisationName: string): boolean {
    if (!verifierOrganisationName) {
      return false;
    }
    return verifierOrganisationName.startsWith("0TestCompany");
  }

  private static isAllowed() {
    return isDomain("app.konfir.com");
  }

  public static init() {
    if (this.isAllowed()) {
      mixpanel.init("dabb92bd07cd782213b22300a6aab179", {
        api_host: "https://mp.konfir.com",
        ignore_dnt: true,
        ...(!this.isPerformanceCookieAccepted() && {
          property_blacklist: ["$device_id"],
          ip: false,
        }),
      });
    }
  }

  private static checkAndRun(fn: () => void) {
    if (this.isAllowed()) {
      try {
        fn();
      } catch (e) {
        this.init();
        fn();
      }
    }
  }

  public static sendEvent(name: EventName, customProperties?: CustomProperties) {
    const eventProperties = {
      category: getCategory(name),
      ...(customProperties &&
        customProperties[EVENT_PROPERTIES.VERIFIER_ORGANISATION_NAME] && {
          [EVENT_PROPERTIES.IS_TEST_VERIFICATION]: String(
            this.isTestVerification(customProperties[EVENT_PROPERTIES.VERIFIER_ORGANISATION_NAME]),
          ),
        }),
      ...customProperties,
    };
    this.checkAndRun(() => mixpanel.track(name, eventProperties));
  }

  public static identifyUser(id: string) {
    this.checkAndRun(() => mixpanel.identify(id));
  }

  public static profileUser(userProperties: UserProfile) {
    this.checkAndRun(() => mixpanel.people.set(userProperties));
  }
}
