export const SIGN_IN = "SIGN_IN";
export const SIGN_UP_DEPRECATED = "SIGN_UP_DEPRECATED";
export const SIGN_UP = "SIGN_UP";
export const VERIFY_USER = "VERIFY_USER";
export const SIGN_OUT = "SIGN_OUT";
export const GET_USER = "GET_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const RESET_ERROR = "RESET_ERROR";
