import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Router from "next/router";

const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 403 && error.response.data.message === "Password expired") {
        Router.replace("/update-password");
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
export type { AxiosRequestConfig, AxiosResponse };
