import axios, { AxiosResponse } from "api/axiosConfig";
import { UploadLinkResponseData } from "api/files";

import { UserPermissions } from "../hooks/useOrganisationSettings";
import { GetOrgUsersResponse } from "../types/GetOrgUsersResponse";
import { Organization } from "../types/Organization";

import { getToken } from "./cognito";

export const getOrganizationByName = async (name: string) => {
  return axios.get<Organization>(`${process.env.API_URL}/organization/name/${name}`);
};

export const getOrganisationById = async (id: string): Promise<Organization> => {
  const token = await getToken();
  return axios
    .get(`${process.env.API_URL}/orgs/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data.data as Organization);
};

export const getOrganizationBySlug = async (name: string) => {
  return axios.get<Organization>(`${process.env.API_URL}/organization/slug/${name}`);
};

export const findOrganizations = async (name: string) => {
  return axios.get<Organization[]>(`${process.env.API_URL}/organizations?name=${name}`);
};

export const getUploadUrl = async (orgId: string, file: File): Promise<AxiosResponse<UploadLinkResponseData>> => {
  const token = await getToken();
  return axios.post<UploadLinkResponseData>(
    `${process.env.API_URL}/orgs/${orgId}/files`,
    {
      fileName: file.name,
      type: file.type,
      fileSize: file.size,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

export const getOrganisationUsers = async (
  organisationId: string,
  page: number,
  limit: number,
): Promise<GetOrgUsersResponse> => {
  const token = await getToken();

  const response = await axios.get<GetOrgUsersResponse>(
    `${process.env.API_URL}/v2/orgs/${organisationId}/users?page=${page}&size=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  if (Array.isArray(response.data)) {
    return response.data;
  } else {
    return {
      content: response.data.content,
      admins: response.data.admins,
      totalElements: response.data.totalElements,
      totalPages: response.data.totalPages,
    };
  }
};

export const getOrganisationEmployers = async (organisationId: string, userName?: string) => {
  const token = await getToken();

  let URL = `${process.env.API_URL}/orgs/${organisationId}/employers`;

  if (userName) {
    URL += `?fullName=${userName}`;
  }

  const response = await axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

export const deleteOrganisationUserById = async (organisationId: string, userId: string | number): Promise<boolean> => {
  const token = await getToken();

  return axios
    .delete(`${process.env.API_URL}/orgs/${organisationId}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.status === 204;
    });
};

export const inviteUserToOrganisation = async (
  organisationId: string,
  data: {
    email: string;
    canSend: boolean; // verifier
    canRespond: boolean; // employer
  },
) => {
  const token = await getToken();

  return axios
    .post(
      `${process.env.API_URL}/orgs/${organisationId}/users/invite`,
      {
        ...data,
        callbackUrl: `${window.location.origin}/sign-up/${data.canRespond ? "employer" : "verifier"}`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      return res.status === 204;
    });
};

export const reinviteUserToOrganisation = async (
  organisationId: string,
  data: {
    email: string;
  },
) => {
  const token = await getToken();

  return axios
    .post(
      `${process.env.API_URL}/orgs/${organisationId}/users/resend-invite`,
      {
        ...data,
        callbackUrl: `${window.location.origin}/sign-in`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      return res.status === 204;
    });
};

export const updateOrganisationUserPermissions = async (
  organisationId: string,
  userId: string | number,
  permissions: UserPermissions,
): Promise<boolean> => {
  const token = await getToken();

  return axios
    .put(
      `${process.env.API_URL}/orgs/${organisationId}/users/${userId}`,
      {
        ...permissions,
        callback: `${window.location.origin}/sign-in`,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      return res.status === 204;
    });
};
