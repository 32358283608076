import { makeStyles } from "@material-ui/core";

interface Props {
  chooseInfoOpen?: boolean;
}

export const useStyles = makeStyles({
  wrapper: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    background: "white",
    border: "1px solid rgb(216, 216, 216)",
    padding: "8px 40px 20px 20px",
    display: "flex",
    zIndex: 101,
  },
  block: {
    margin: "1rem 0",
  },
  title: {
    margin: 0,
    marginBottom: 4,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
  buttonRoot: {
    marginRight: 12,
    height: "46px !important",
  },
  buttonLabel: {
    fontSize: "14px !important",
    height: "44px !important",
    padding: "0 20px !important",
  },
  iconWrapper: {
    marginRight: 16,
  },
  link: {
    textDecoration: "underline",
    color: "#34C4EB",
  },

  // Mobile Cookie consent

  root: {
    width: "100%",
    height: (props: Props) => (props.chooseInfoOpen ? "100%" : "auto"),
    overflow: "scroll",
    background: "white",
    border: "1px solid rgb(216, 216, 216)",
    padding: (props: Props) => (props.chooseInfoOpen ? "24px 16px 0 24px" : "24px 16px"),
  },

  iconAndHeading: {
    display: "flex",
    gap: 8,
  },

  headContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "36px",
  },

  spacer: {
    height: "20px",
    width: "20px",
  },

  closeIcon: {
    width: 12,
    height: 12,
    paddingRight: "20px",
  },

  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },

  backdropStyle: {
    zIndex: 500,
    alignItems: "flex-end",
  },

  textLimiter: {
    height: "24px",
    color: "#fff",
    width: "100%",
  },

  stickyCTA: {
    position: "sticky",
    bottom: 0,
    background: "#fff",
    zIndex: 201,
  },
});
