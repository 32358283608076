import { Switch } from "@material-ui/core";
import React, { FC } from "react";

import Typography from "../shared/Typography";

import { useStyles } from "./Cookie.styles";

interface ChooseInfoProps {
  performanceConsent: boolean;
  togglePerformanceConsent: () => void;
}

export const ChooseInfo: FC<ChooseInfoProps> = ({ performanceConsent, togglePerformanceConsent }) => {
  const classes = useStyles({});

  return (
    <>
      <div className={classes.block}>
        <Typography variant="h6" color="textPrimary" className={classes.title}>
          Necessary cookies
        </Typography>
        <Typography color="textSecondary" className={classes.body}>
          Necessary cookies enable core functionality such as security, network management, and accessibility. You may
          disable these by changing you browser settings, but this may affect how the website functions.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant="h6" color="textPrimary" className={classes.title}>
          Performance cookies <Switch checked={performanceConsent} onChange={togglePerformanceConsent} />
        </Typography>
        <Typography color="textSecondary" className={classes.body}>
          We'd like to set performance and customisation cookies to help us understand how our website is being used,
          how effective our marketing campaigns are, or to customise your experience.
        </Typography>
      </div>
    </>
  );
};
