import { AnyAction } from "redux";

import { Activity } from "@/types/Activity";
import { FAILURE, REQUEST, SUCCESS } from "@/utils/actionType.util";

import { SIGN_OUT } from "../user/actionTypes";

import {
  CREATE_VERIFICATION_REQUEST,
  GET_EMPLOYERS_LIST,
  GET_VERIFICATION_REQUEST,
  UPDATE_FEEDBACK_LOOP,
  UPDATE_VERIFICATION_REVIEWED_STATUS,
} from "./actionTypes";
import { shouldShowFinishReviewingModal } from "./helpers";
import { VerificationStore } from "./types";

const initialVerificationState: VerificationStore = {
  loading: false,
  error: null,
  selectedVerification: null,
  showFinishReviewingModal: null,
  createdRequest: null,
  isEmployersListLoading: false,
  employersList: [],
};

const verificationReducer = (state = initialVerificationState, action: AnyAction) => {
  switch (action.type) {
    case REQUEST(CREATE_VERIFICATION_REQUEST):
    case REQUEST(GET_VERIFICATION_REQUEST):
      return {
        ...state,
        loading: true,
      };
    case REQUEST(GET_EMPLOYERS_LIST):
      return {
        ...state,
        isEmployersListLoading: true,
      };
    case SUCCESS(GET_EMPLOYERS_LIST):
      return {
        ...state,
        isEmployersListLoading: false,
        employersList: action.payload.employersList,
      };
    case SUCCESS(CREATE_VERIFICATION_REQUEST):
      return {
        ...state,
        loading: false,
        createdRequest: action.payload.request,
      };
    case SUCCESS(GET_VERIFICATION_REQUEST):
      return {
        ...state,
        loading: false,
        selectedVerification: action.payload.request,
        showFinishReviewingModal:
          action.payload.request.activities && shouldShowFinishReviewingModal(action.payload.request.activities),
      };
    case SUCCESS(UPDATE_FEEDBACK_LOOP):
      const activityFound = (<Activity[]>state.selectedVerification?.activities).find(
        (activity) => activity.id === action.payload.id,
      );
      const activityUpdated = { ...activityFound, ...action.payload };
      const activities = (<Activity[]>state.selectedVerification?.activities).map((activity) =>
        activity.id === activityUpdated.id ? activityUpdated : activity,
      );
      return {
        ...state,
        loading: false,
        showFinishReviewingModal: shouldShowFinishReviewingModal(activities),
        selectedVerification: {
          ...state.selectedVerification,
          activities,
        },
      };
    case SUCCESS(UPDATE_VERIFICATION_REVIEWED_STATUS):
      return {
        ...state,
        loading: false,
        selectedVerification: {
          ...state.selectedVerification,
          reviewed: action.payload,
        },
      };
    case FAILURE(CREATE_VERIFICATION_REQUEST):
    case FAILURE(GET_VERIFICATION_REQUEST):
    case FAILURE(UPDATE_FEEDBACK_LOOP):
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FAILURE(GET_EMPLOYERS_LIST):
      return {
        ...state,
        isEmployersListLoading: false,
        error: action.payload.error,
      };
    case FAILURE(UPDATE_VERIFICATION_REVIEWED_STATUS):
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SUCCESS(SIGN_OUT):
      return initialVerificationState;
    default:
      return state;
  }
};

export default verificationReducer;
