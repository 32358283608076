export enum TimelineActivityInsightEvidence {
  JOBSEEKERS_ALLOWANCE = "job_seekers_allowance",
  ATTENDANCE_ALLOWANCE = "attendance_allowance",
  DISABILITY_LIVING_ALLOWANCE = "disability_living_allowance",
  PERSONAL_INDEPENDENCE_PAYMENT = "personal_independence_payment",
  SEVERE_DISABLEMENT_ALLOWANCE = "severe_disablement_allowance",
  CHRISTMAS_BONUS = "christmas_bonus",
  COLD_WEATHER_PAYMENT = "cold_weather_payment",
  INCOME_SUPPORT = "income_support",
  MINIMUM_INCOME_GUARANTEE = "minimum_income_guarantee",
  MORTGAGE_INTEREST_DIRECT = "mortgage_interest_direct",
  SOCIAL_FUND = "social_fund",
  UNIVERSAL_CREDIT = "universal_credit",
  WINTER_FUEL_PAYMENT = "winter_fuel_payment",
  COST_OF_LIVING_PAYMENT = "cost_of_living_payment",
  COVID_19_SUPPORT_PAYMENT = "covid_19_support_payment",
  DWP_REFUND = "dwp_refund",
  WORKING_TAX_CREDITS = "working_tax_credits",
  CHILD_MAINTENANCE = "child_maintenance",
  CHILD_TAX_CREDITS = "child_tax_credits",
  MATERNITY_ALLOWANCE = "maternity_allowance",
  AGE_RELATED_PAYMENT = "age_related_payment",
  PENSION_CREDIT = "pension_credit",
  STATE_PENSION = "state_pension",
  WIDOWED_MOTHERS_ALLOWANCE = "widowed_mothers_allowance",
  WIDOWED_PARENTS_ALLOWANCE = "widowed_parents_allowance",
  BEREAVEMENT_ALLOWANCE = "bereavement_allowance",
  BEREAVEMENT_PAYMENT = "bereavement_payment",
  WIDOWS_BENEFIT = "widows_benefit",
  CARERS_ALLOWANCE = "carers_allowance",
  TRAINING_PAYMENT = "training_payment",
  EMPLOYMENT_SUPPORT_ALLOWANCE = "employment_support_allowance",
  INCAPACITY_BENEFIT = "incapacity_benefit",
  INDUSTRIAL_INJURIES_BENEFIT = "industrial_injuries_benefit",
  INDUSTRIAL_INJURIES_DISABLEMENT_BENEFIT = "industrial_injuries_disablement_benefit",
  REDUCED_EARNINGS_ALLOWANCE = "reduced_earnings_allowance",
  STUDENT_LOAN = "student_loan",
  PENSION = "pension",
  EXPENDITURE_WITHOUT_INCOME = "no_income",
}

export enum TimelineActivityInsightType {
  ADDITIONAL_CARE_SUPPORT_RECEIVED = "additional_care_support_received",
  TEMPORARY_WORK_DISRUPTION = "temporary_work_disruption",
  MATERNITY_LEAVE = "maternity_leave",
  SKILL_DEVELOPMENT_PHASE = "skill_development_phase",
  FINANCIAL_SUPPORT_RECEIVED = "financial_support_received",
  MANAGING_CHILDCARE_COSTS = "managing_childcare_costs",
  RESPONSIBLE_FOR_ANOTHERS_CARE = "responsible_for_anothers_care",
  NEARING_OR_IN_RETIREMENT = "nearing_or_in_retirement",
  ACTIVELY_JOB_SEARCHING = "actively_job_searching",
  RECENTLY_EXPERIENCED_LOSS = "recently_experienced_loss",
  IN_EDUCATION = "in_education",
  NO_INCOME = "no_income",
  FRAUD = "fraud",
  TRADING_DATES = "trading_dates",
}

export type ReportedFraudulentEmployerPayload = {
  employerName: string;
  verifierReviewedAsUseful?: boolean;
};

export type TimelineActivityInsightEmployerDetails = {
  score: number;
  fraudulentEmployer: ReportedFraudulentEmployerPayload;
};

export type TimelineActivityInsightTradingDateMismatch = {
  employerName: string;
  incorporationDate: string;
  dissolutionDate?: string;
};

export interface Insight {
  type: TimelineActivityInsightType;
  evidence:
    | TimelineActivityInsightEvidence
    | TimelineActivityInsightEmployerDetails
    | TimelineActivityInsightTradingDateMismatch;
  startDate?: string;
  endDate?: string | null;
}

export interface InsightTransformed extends Pick<Insight, "type"> {
  evidenceList: {
    name: TimelineActivityInsightEvidence;
    startDate: string;
    endDate: string | null;
  }[];
}

export enum TimelineActivityAlertType {
  EMPLOYMENT_START_DATE = "employment_start_date",
  EMPLOYMENT_END_DATE = "employment_end_date",
  EMPLOYER_NAME = "employer_name",
}

export const alertNameUI: Record<TimelineActivityAlertType, string> = {
  [TimelineActivityAlertType.EMPLOYMENT_START_DATE]: "Start Date",
  [TimelineActivityAlertType.EMPLOYMENT_END_DATE]: "End Date",
  [TimelineActivityAlertType.EMPLOYER_NAME]: "Employer Name",
};

export type TimelineAlert = {
  type: TimelineActivityAlertType;
  significance: "major" | "minor";
  diff: number;
  label: string;
};
