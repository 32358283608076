import { isBefore, parseISO, subYears } from "date-fns";
import { addDays, format, subDays } from "date-fns/fp";

export function getYesterday(date: Date): Date {
  return subDays(1)(date);
}

export function getTomorrow(date: Date): Date {
  return addDays(1)(date);
}

export function toISO(date: number | string): string {
  if (typeof date === "number") {
    return new Date(date).toISOString();
  }
  return date;
}

export const formatDate = (date: string | number, dateFormat = "d MMM yyyy"): string => {
  if (typeof date === "number") {
    return format(dateFormat)(date);
  }
  return format(dateFormat)(parseISO(date));
};

export const renderDateOrMessage = (date: string | null, message?: string) => {
  if (!date) {
    return message || null;
  }

  return formatDate(date, "dd/MM/yyyy");
};

export const compareDates = (dateOne: string | number, dateTwo: string | number) => {
  let dateOneTimestamp: number;
  let dateTwoTimestamp: number;

  if (typeof dateOne === "string") {
    dateOneTimestamp = parseISO(dateOne).getTime();
  } else {
    dateOneTimestamp = dateOne;
  }

  if (typeof dateTwo === "string") {
    dateTwoTimestamp = parseISO(dateTwo).getTime();
  } else {
    dateTwoTimestamp = dateTwo;
  }

  return dateOneTimestamp - dateTwoTimestamp;
};

export const toDateString = (date: Date | undefined | null): string | null => {
  if (!date) {
    return null;
  }
  const isDate = !isNaN(new Date(date).getTime());
  if (!isDate) {
    return null;
  }
  return format("yyyy-MM-dd")(date);
};

export const isDateBefore = (date: Date | string, years: number) => {
  const dateParsed = typeof date === "string" ? parseISO(date) : date;

  return isBefore(dateParsed, subYears(new Date(), years));
};
