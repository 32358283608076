import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BreadcrumbState {
  checkpoints: Record<string, string>;
}

const initialState: BreadcrumbState = {
  checkpoints: {},
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    save: (state, action: PayloadAction<{ checkpoint: string; path: string }>) => {
      state.checkpoints[action.payload.checkpoint] = action.payload.path;
    },
  },
});

export const { save: saveAction } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
