import { FC, useEffect } from "react";

import useScript from "hooks/useScript";

const Usetiful: FC = () => {
  const status = useScript("https://www.usetiful.com/dist/usetiful.js", {
    id: "usetifulScript",
    datasetToken: "c956e2a40ead8b2dc7cc1e1b9a5f2b9c",
  });

  /**
   * used to set configurations
   * once usetifulTags is true
   */
  useEffect(() => {
    if (status === "ready") {
      console.log("usetiful is ready");
    }
  }, [status]);

  return null;
};

export default Usetiful;
