import { hotjar } from "react-hotjar";

let isEnabled = false;

export const initialise = () => {
  hotjar.initialize({ id: 2734752, sv: 6 });
  isEnabled = true;
};

export const identify = (email: string) => {
  if (isEnabled) {
    hotjar.identify(email, { email });
  }
};
