import { AnyAction } from "redux";

import { SET_ERROR } from "./actionTypes";

export interface ErrorState {
  error: string | null;
}

const initialState: ErrorState = {
  error: null,
};

const errorReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default errorReducer;
