import { Classes, FontSize, FontWeight } from "components/shared/Typography/index";

export const getTypographyClassname = (classes: Classes, size?: FontSize, weight?: FontWeight) => {
  let className = "";

  if (size) {
    className += classes[`size-${size}`];
  }

  if (weight) {
    if (className.length > 0) {
      className += " ";
    }
    className += classes[`weight-${weight}`];
  }

  return className;
};
