function pruneObject(data: any) {
  const newData: any = {};

  for (const key in data) {
    if (data[key] !== "") {
      newData[key] = data[key];
    }
  }

  return newData;
}

export default pruneObject;
