import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import Cookie from "../../icons/Cookie";
import Typography from "../shared/Typography";

import { ChooseInfo } from "./ChooseInfo";
import { useStyles } from "./Cookie.styles";
import { CookieMobile } from "./CookieMobile";

interface CookieConsentProps {
  display: boolean;
  save: (performanceConsent: boolean) => void;
  rejectAll: () => void;
}

export const CookieConsent: React.FC<CookieConsentProps> = ({ display, save, rejectAll }) => {
  const classes = useStyles({});
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    if (display && mobile) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [mobile, display]);

  const [performanceConsent, setPerformanceConsent] = useState(true);

  const togglePerformanceConsent = () => {
    setPerformanceConsent(!performanceConsent);
  };

  if (!display) {
    return null;
  }

  if (mobile) {
    return (
      <CookieMobile
        save={save}
        performanceConsent={performanceConsent}
        togglePerformanceConsent={togglePerformanceConsent}
      />
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.iconWrapper}>
        <div className={classes.block}>
          <Cookie />
        </div>
      </div>
      <div>
        <div className={classes.block}>
          <Typography variant="h6" color="textPrimary" className={classes.title}>
            Our use of cookies
          </Typography>
          <Typography color="textSecondary" className={classes.body}>
            We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make
            improvements by measuring how you use the site. These will be set only if you accept. For more detailed
            information about the cookies we use, see our{" "}
            <Link href="/legals/cookie-notice">
              <span className={classes.link}>Cookies page</span>
            </Link>
          </Typography>
        </div>
        <div className={classes.block}>
          <Button
            data-testid="button-accept-cookie"
            variant="contained"
            type="submit"
            onClick={() => save(true)}
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
          >
            Accept all cookies
          </Button>
          <Button
            variant="outlined"
            data-testid="button-reject-cookie"
            type="submit"
            onClick={() => rejectAll()}
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
          >
            Reject all cookies
          </Button>
        </div>
        <ChooseInfo performanceConsent={performanceConsent} togglePerformanceConsent={togglePerformanceConsent} />
        <div className={classes.block}>
          <Button
            variant="contained"
            type="submit"
            onClick={() => save(performanceConsent)}
            classes={{
              root: classes.buttonRoot,
              label: classes.buttonLabel,
            }}
          >
            Save and close
          </Button>
        </div>
      </div>
    </div>
  );
};
