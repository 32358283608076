import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";

import {
  deleteOrganisationUserById,
  getOrganisationById,
  getOrganisationUsers,
  updateOrganisationUserPermissions,
} from "../../api/organization";
import { UserPermissions } from "../../hooks/useOrganisationSettings";
import { FAILURE, REQUEST, SUCCESS } from "../../utils/actionType.util";
import { AppDispatch } from "../index";
import { setSnackbar } from "../snackbar/actions";
import { MessageType } from "../snackbar/types";

import {
  DELETE_ORGANISATION_USER,
  GET_ORGANISATION,
  GET_ORGANISATION_USERS,
  SET_SETTINGS_MENU_OPEN,
  UPDATE_ORGANISATION_USER_PERMISSIONS,
} from "./actionTypes";
import { OrganisationState } from "./types";

export const getOrganisationAction = (organisationId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: REQUEST(GET_ORGANISATION) });
    const org = await getOrganisationById(organisationId);
    dispatch({
      type: SUCCESS(GET_ORGANISATION),
      payload: org,
    });
  } catch (err) {
    dispatch({
      type: FAILURE(GET_ORGANISATION),
      payload: {
        error: err?.message,
      },
    });
  }
};

export const getOrganisationUsersAction =
  (organisationId: string, page: number, limit: number) => (dispatch: AppDispatch) => {
    dispatch({ type: REQUEST(GET_ORGANISATION_USERS) });
    getOrganisationUsers(organisationId, page, limit)
      .then((data) => {
        dispatch({
          type: SUCCESS(GET_ORGANISATION_USERS),
          payload: {
            organisationUsers: data.content,
            organisationAdmins: data.admins,
            page: data.page,
            limit: data.size,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(GET_ORGANISATION_USERS),
          payload: {
            error: err.message,
          },
        });
      });
  };

export const deleteOrganisationUserAction =
  (organisationId: string, userId: string | number, page: number, limit: number) => (dispatch: AppDispatch) => {
    dispatch({ type: REQUEST(DELETE_ORGANISATION_USER) });
    deleteOrganisationUserById(organisationId, userId)
      .then((deleted) => {
        if (deleted) {
          dispatch({ type: SUCCESS(DELETE_ORGANISATION_USER) });
          dispatch(getOrganisationUsersAction(organisationId, page, limit));
          dispatch(setSnackbar(true, MessageType.SUCCESS, "User successfully removed"));
        }
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(DELETE_ORGANISATION_USER),
          payload: {
            error: err.message,
          },
        });
      });
  };

export const updateOrganisationUserPermissionsAction =
  (
    organisationId: string,
    userId: string | number,
    permissions: UserPermissions,
    callback: () => void,
    page: number,
    limit: number,
  ) =>
  (dispatch: ThunkDispatch<OrganisationState, unknown, AnyAction>) => {
    dispatch({ type: REQUEST(UPDATE_ORGANISATION_USER_PERMISSIONS) });
    updateOrganisationUserPermissions(organisationId, userId, permissions)
      .then((updated) => {
        if (updated) {
          dispatch({ type: SUCCESS(UPDATE_ORGANISATION_USER_PERMISSIONS) });
          dispatch(getOrganisationUsersAction(organisationId, page, limit));
          callback();
          dispatch(setSnackbar(true, MessageType.SUCCESS, "Successfully changed user permissions"));
        }
      })
      .catch((err) => {
        dispatch({
          type: FAILURE(UPDATE_ORGANISATION_USER_PERMISSIONS),
          payload: {
            error: err.message,
          },
        });
      });
  };

export const setSettingsMenuOpenAction = (isOpen: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_SETTINGS_MENU_OPEN,
    payload: isOpen,
  });
};
