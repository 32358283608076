import { createSlice } from "@reduxjs/toolkit";

export interface FeatureFlagsState {
  loading: boolean;
  isReady: boolean;
  isIdentified: boolean;
}

const initialState: FeatureFlagsState = {
  loading: false,
  isReady: false,
  isIdentified: false,
};

const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    setLoadingAction: (state: FeatureFlagsState) => {
      state.loading = true;
      state.isReady = false;
      state.isIdentified = false;
    },
    setReadyAction: (state: FeatureFlagsState) => {
      state.loading = false;
      state.isReady = true;
      state.isIdentified = false;
    },
    setIdentifiedAction: (state: FeatureFlagsState) => {
      state.loading = false;
      state.isReady = true;
      state.isIdentified = true;
    },
  },
});

export const { setReadyAction, setLoadingAction, setIdentifiedAction } = featureFlagsSlice.actions;
export default featureFlagsSlice.reducer;
