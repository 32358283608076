import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";

import { SignInValues } from "../forms/SignIn";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.USER_POOL_ID as string,
  ClientId: process.env.CLIENT_ID as string,
});

let authenticationDetails: AuthenticationDetails | null = null;

export function login({ email, password }: SignInValues): Promise<CognitoUserSession> {
  authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise<CognitoUserSession>((resolve, reject) => {
    if (cognitoUser && authenticationDetails) {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(userSession) {
          resolve(userSession);
        },
        onFailure(err) {
          reject(err);
        },
      });
    }
  });
}

export async function getUserSession(): Promise<CognitoUserSession> {
  const cognitoUser = userPool.getCurrentUser();

  return new Promise<CognitoUserSession>((resolve, reject) => {
    if (cognitoUser !== null) {
      cognitoUser.getSession(function (err: Error | null, result: null | CognitoUserSession) {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      });
    } else {
      reject();
    }
  });
}

export function logout() {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser !== null) {
    cognitoUser.signOut();
  }
}

export async function getToken() {
  const userSession = await getUserSession();
  return userSession.getAccessToken().getJwtToken();
}
