import DateFnsUtils from "@date-io/date-fns";
import { Snackbar } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { compose } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import "../../styles/globals.css";

import { withLDProvider } from "launchdarkly-react-client-sdk";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import FeatureFlagsProvider from "components/FeatureFlagsProvider";

import { CookieConsent } from "../components/CookieConsent";
import Usetiful from "../components/Usetiful/Usetiful";
import useActiveOrganisation from "../hooks/useActiveOrganisation";
import useCookieConsent from "../hooks/useCookieConsent";
import useCurrentUser from "../hooks/useCurrentUser";
import { initialise } from "../infrastructure/hotjar";
import { clearRedirect } from "../storage";
import { RootState, wrapper } from "../store";
import { clearSnackbar, setSnackbar } from "../store/snackbar/actions";
import { MessageType, SnackbarState } from "../store/snackbar/types";
import theme from "../theme";
import Analytics from "../utils/analytics";
import { isDomain } from "../utils/domain";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const { loadUser, isLoggedIn } = useCurrentUser();
  const { isTest, hasLoaded } = useActiveOrganisation();
  const shouldShowUsetiful = isLoggedIn && !isTest && hasLoaded;

  const dispatch = useDispatch();
  const error = useSelector<RootState>((state) => state.user.error);

  const displayErrorDetails = useSelector<RootState>((state) => state.user.displayErrorDetails);
  const { snackbarOpen, snackbarMessage, snackbarType } = useSelector<RootState, SnackbarState>(
    (state) => state.snackbar,
  );
  const { isPreferenceSaved, hasPerformanceConsent, save, rejectAll } = useCookieConsent();

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    clearRedirect();
  }, [router]);

  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    if (error && error !== "Incorrect username or password." && error !== "Email not found") {
      dispatch(setSnackbar(true, MessageType.ERROR, displayErrorDetails ? (error as string) : "Something went wrong"));
    }
  }, [error]);

  useEffect(() => {
    Analytics.init();
  }, [hasPerformanceConsent]);

  useEffect(() => {
    if (isDomain("app.konfir.com") && hasPerformanceConsent) {
      initialise();
    }
  }, [hasPerformanceConsent]);

  if (isDomain("app.konfir.com")) {
    Sentry.init({
      dsn: "https://bbd789e98e544d8c928b76b0d3151699@o1280356.ingest.sentry.io/6483504",
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: 0.2,
    });
  }

  const closeSnack = () => dispatch(clearSnackbar());

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>Konfir</title>
      </Head>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarOpen}
              onClose={closeSnack}
              autoHideDuration={5000}
              data-testid="snackbar"
            >
              <Alert severity={snackbarType} elevation={0} variant="filled" data-testid={`alert-${snackbarType}`}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
            {shouldShowUsetiful && <Usetiful />}
            <FeatureFlagsProvider>{getLayout(<Component {...pageProps} />)}</FeatureFlagsProvider>
            <CookieConsent display={!isPreferenceSaved} save={save} rejectAll={rejectAll} />
          </>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
}

export default compose(
  withLDProvider({
    clientSideID: process.env.LD_CLIENT_ID!,
    context: {
      kind: "user",
      key: "anonymous",
      name: "Anon Ymous",
      email: "anon@ymous.com",
    },
  }),
  wrapper.withRedux,
  Sentry.withProfiler,
)(MyApp);
