export enum MessageType {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export type SnackbarState = {
  snackbarOpen: boolean;
  snackbarType: MessageType;
  snackbarMessage: string;
};
