import { AnyAction } from "redux";

import { FAILURE, REQUEST, SUCCESS } from "../../utils/actionType.util";

import {
  FORGOT_PASSWORD,
  GET_USER,
  RESET_ERROR,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_DEPRECATED,
  VERIFY_USER,
} from "./actionTypes";
import { UserState } from "./types";

const initialUserState: UserState = {
  loading: false,
  isLoggedIn: false,
  error: "",
  displayErrorDetails: false,
  verifyingData: {},
  user: null,
};

const userReducer = (state = initialUserState, action: AnyAction) => {
  switch (action.type) {
    case RESET_ERROR:
      return {
        ...state,
        error: "",
        displayErrorDetails: false,
      };
    case REQUEST(SIGN_UP_DEPRECATED):
    case REQUEST(SIGN_UP):
    case REQUEST(SIGN_OUT):
    case REQUEST(VERIFY_USER):
    case REQUEST(FORGOT_PASSWORD):
    case REQUEST(GET_USER):
      return {
        ...state,
        loading: true,
        error: "",
      };
    case REQUEST(SIGN_IN):
      return {
        ...state,
        error: "",
      };
    case SUCCESS(SIGN_UP_DEPRECATED):
    case SUCCESS(SIGN_UP):
    case SUCCESS(VERIFY_USER):
      return {
        ...state,
        verifyingData: {
          ...action.payload,
        },
        loading: false,
      };
    case SUCCESS(FORGOT_PASSWORD):
      return {
        ...state,
        loading: false,
      };
    case SUCCESS(SIGN_IN):
    case SUCCESS(GET_USER):
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        user: { ...action.payload },
      };
    case SUCCESS(SIGN_OUT):
    case FAILURE(GET_USER):
      return initialUserState;
    case FAILURE(VERIFY_USER):
    case FAILURE(SIGN_IN):
    case FAILURE(FORGOT_PASSWORD):
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        displayErrorDetails: action.payload.displayErrorDetails,
      };
    case FAILURE(SIGN_UP_DEPRECATED):
    case FAILURE(SIGN_UP):
      return {
        ...state,
        loading: false,
        error: "We’re having trouble setting up your account. Please contact help@konfir.com",
        displayErrorDetails: true,
      };
    default:
      return state;
  }
};

export default userReducer;
