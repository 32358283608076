import { AnyAction } from "redux";

import { VerificationRequestValues } from "../verification/types";

import { CLEAR_SAVED_STATUS, RESET_VERIFICATION_DATA, SAVE_VERIFICATION_DATA } from "./actionTypes";

export interface SignupVerificationState {
  verification: Partial<VerificationRequestValues> | null;
  isVerificationSaved: boolean;
}

const initialVerificationValues: SignupVerificationState = {
  verification: null,
  isVerificationSaved: false,
};

const signupVerificationReducer = (state = initialVerificationValues, action: AnyAction) => {
  switch (action.type) {
    case SAVE_VERIFICATION_DATA:
      return { verification: action.payload, isVerificationSaved: true };
    case RESET_VERIFICATION_DATA:
      return { ...state, verification: null };
    case CLEAR_SAVED_STATUS:
      return { ...state, isVerificationSaved: false };
    default:
      return state;
  }
};

export default signupVerificationReducer;
