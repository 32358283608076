import { Backdrop, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Image from "next/image";
import Link from "next/link";
import React, { FC, useState } from "react";

import Cookie from "../../icons/Cookie";
import Typography from "../shared/Typography";

import { ChooseInfo } from "./ChooseInfo";
import { useStyles } from "./Cookie.styles";

interface CookieMobileProps {
  save: (performanceConsent: boolean) => void;
  performanceConsent: boolean;
  togglePerformanceConsent: () => void;
}

export const CookieMobile: FC<CookieMobileProps> = ({ save, performanceConsent, togglePerformanceConsent }) => {
  const [chooseInfoOpen, setChooseInfoOpen] = useState(false);
  const classes = useStyles({ chooseInfoOpen });

  return (
    <Backdrop open style={{ zIndex: 500, alignItems: "flex-end" }}>
      <div className={classes.root}>
        {chooseInfoOpen && (
          <div className={classes.headContainer}>
            <div className={classes.spacer} />
            <Image src="/logo-mobile.svg" alt="Konfir logo" width={60} height={20} />
            <div className={classes.closeIcon} onClick={() => setChooseInfoOpen(false)}>
              <CloseIcon />
            </div>
          </div>
        )}
        <div className={classes.iconAndHeading}>
          <Cookie />
          <Typography variant="h6" color="textPrimary" className={classes.title}>
            Our use of cookies
          </Typography>
        </div>
        <div>
          <div className={classes.block}>
            <Typography color="textSecondary" className={classes.body}>
              We use necessary cookies to make our site work. We'd also like to set analytics cookies that help us make
              improvements by measuring how you use the site. These will be set only if you accept. For more detailed
              information about the cookies we use, see our{" "}
              <Link href="/legals/cookie-notice">
                <span className={classes.link}>Cookies page</span>
              </Link>
            </Typography>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              data-testid="button-accept-cookie"
              variant="contained"
              fullWidth
              type="submit"
              onClick={() => save(true)}
              classes={{
                root: classes.buttonRoot,
                label: classes.buttonLabel,
              }}
            >
              Accept all
            </Button>
            {!chooseInfoOpen ? (
              <Button
                variant="outlined"
                data-testid="button-let-me-choose"
                onClick={() => setChooseInfoOpen(true)}
                fullWidth
                classes={{
                  root: classes.buttonRoot,
                  label: classes.buttonLabel,
                }}
              >
                Let me choose
              </Button>
            ) : (
              <>
                <ChooseInfo
                  performanceConsent={performanceConsent}
                  togglePerformanceConsent={togglePerformanceConsent}
                />
                <div className={classes.stickyCTA}>
                  <Button
                    data-testid="button-accept-cookie"
                    variant="contained"
                    fullWidth
                    type="submit"
                    onClick={() => save(performanceConsent)}
                    classes={{
                      root: `${classes.buttonRoot} `,
                      label: classes.buttonLabel,
                    }}
                  >
                    Accept selected only
                  </Button>
                  <div className={classes.textLimiter} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};
