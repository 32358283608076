import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../store";
import { loadUser } from "../store/user/actions";
import { Organization } from "../types/Organization";
import { User } from "../types/User";
import { UserRole } from "../types/UserRole";
import { UserStatus } from "../types/UserStatus";

export interface UseCurrentUser {
  id?: string;
  sub?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | undefined;
  isEmployer: boolean;
  isVerifier: boolean;
  hasBothRoles: boolean;
  isAdmin: boolean;
  isActive: boolean;
  organisation?: Organization;
  organisations: Organization[];
  isLoading: boolean;
  isLoggedIn: boolean;
  hasOrganisation: boolean;
  loadUser: () => void;
}

export default function useCurrentUser(): UseCurrentUser {
  const { query } = useRouter();
  const dispatch = useAppDispatch();
  const user = useSelector<RootState, User | null>((state) => state.user.user);
  const loading = useSelector<RootState, boolean>((state) => state.user.loading);
  const currentOrg = user?.organisations?.find(
    (organisation: Organization) => String(organisation.id) === query.organisationId,
  );

  const isEmployer = !!currentOrg?.roles.includes(UserRole.EMPLOYER);
  const isVerifier = !!currentOrg?.roles.includes(UserRole.VERIFIER);
  const hasBothRoles = isEmployer && isVerifier;

  const load = useCallback(async () => {
    dispatch(loadUser());
  }, [dispatch]);

  return {
    id: user?.id,
    sub: user?.sub,
    isLoggedIn: !!user,
    isEmployer,
    isVerifier,
    hasBothRoles,
    isAdmin: !!currentOrg?.roles.includes(UserRole.ADMIN),
    isActive: user?.state === UserStatus.ACTIVE,
    organisation: user?.organisation,
    organisations: user?.organisations || [],
    isLoading: loading,
    hasOrganisation: !!user?.organisation,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    loadUser: load,
  };
}
