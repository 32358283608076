import axios from "api/axiosConfig";

import { User } from "../types/User";

import { getToken } from "./cognito";

export const getCurrentUser = async (token: string) => {
  return axios.get<User>(`${process.env.API_URL}/user`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const switchOrganisation = async (orgId: string) => {
  const token = await getToken();
  return axios.put(`${process.env.API_URL}/user/organisation/${orgId}/switch`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
