import { makeStyles, Typography as MuiTypography, TypographyProps as MuiTypographyProps } from "@material-ui/core";
import classnames from "classnames";
import React from "react";

import { getTypographyClassname } from "components/shared/Typography/getTypographyClassName";

const useStyles = makeStyles({
  "size-10": {
    fontSize: 10,
    lineHeight: "16px",
  },
  "size-small": {
    fontSize: 12,
    lineHeight: "16px",
  },
  "size-regular": {
    fontSize: 14,
    lineHeight: "20px",
  },
  "size-large": {
    fontSize: 16,
    lineHeight: "24px",
  },
  "size-18": {
    fontSize: 18,
    lineHeight: "18px",
  },
  "size-22": {
    fontSize: 22,
    lineHeight: "22px",
  },
  "size-24": {
    fontSize: 24,
    lineHeight: "34px",
  },
  "weight-regular": {
    fontWeight: 400,
  },
  "weight-medium": {
    fontWeight: 500,
  },
  "weight-semi-bold": {
    fontWeight: 600,
  },
});

export interface Classes extends ReturnType<typeof useStyles> {
  [index: string]: string;
}

export type FontSize = "10" | "small" | "regular" | "large" | "18" | "22" | "24";
export type FontWeight = "regular" | "medium" | "semi-bold";

export interface TypographyProps extends MuiTypographyProps {
  size?: FontSize;
  weight?: FontWeight;
}

const Typography = ({ size, weight, className, ...otherProps }: TypographyProps) => {
  const classes: Classes = useStyles();
  const typographyClasses = classnames(className, getTypographyClassname(classes, size, weight));

  return <MuiTypography className={typographyClasses} {...otherProps} />;
};

export default Typography;
